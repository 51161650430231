import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UIColorContext } from '../../providers/UIColorProvider';

function Button({
  children,
  color,
  onChange,
  onClick,
  value,
  className,
}) {
  const { colorClasses } = useContext(UIColorContext);
  return (
    <button
      type="button"
      className={`px-4 py-2 rounded shadow-md border ${colorClasses[color]} ${className}`}
      onChange={onChange}
      onClick={onClick}
      value={value}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  color: 'bg-blue-500',
  onChange: () => {},
  onClick: () => {},
  value: '',
  className: '',
};

export default Button;
