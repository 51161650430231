import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useNavigate } from 'react-router-dom';
import useFetchWithAuth from '../../../hooks/useFetchWithAuth';

function CreateEventSingle() {
  const {
    data: users, loading, error, post,
  } = useFetchWithAuth('/api/dropdowns/users');
  const {
    data: leaderboards, loading: leaderboardLoading, error: leaderboardError,
  } = useFetchWithAuth('/api/dropdowns/leaderboards');
  const [eventName, setEventName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [participants, setParticipants] = useState([]);
  const [multipleParticipants, setMultipleParticipants] = useState('');
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('');
  const navigate = useNavigate();

  const handleEventNameChange = (e) => {
    setEventName(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    if (endDate === '' || endDate < e.target.value) {
      setEndDate(e.target.value);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    if (startDate === '' || startDate > e.target.value) {
      setStartDate(e.target.value);
    }
  };

  const handleParticipantsChange = (selected) => {
    setParticipants(selected);
  };

  const handleMultipleParticipantsChange = (e) => {
    setMultipleParticipants(e.target.value === 'true');
    if (e.target.value === 'false') {
      setParticipants([]);
    }
  };

  const handleLeaderboardChange = (e) => {
    setSelectedLeaderboard(e.target.value);
  };

  const formData = {
    name: eventName,
    startDate,
    endDate,
    participants: multipleParticipants ? participants : [],
    leaderboard: selectedLeaderboard,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await post(formData, '/api/events/createEventSingle');
      if (response.status === 'success') {
        navigate('/my-events');
      }
    } catch (err) {
      console.error('Error creating event:', err);
    }
  };

  const shouldShowSubmitButton = () => eventName !== '' && startDate !== '' && endDate !== '' && multipleParticipants !== '' && (!multipleParticipants || (multipleParticipants && participants.length > 0)) && selectedLeaderboard !== '';

  if (loading || leaderboardLoading) return <div>Loading...</div>;
  if (error || leaderboardError) {
    return (
      <div>
        Error:
        {error || leaderboardError}
      </div>
    );
  }

  return (
    <div>
      <h1>Create Single Event</h1>
      <form onSubmit={handleSubmit}>
        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="eventName" className="block text-sm font-medium text-gray-700 mt-2">
              Event Name:
              <input
                type="text"
                value={eventName}
                onChange={handleEventNameChange}
                className="p-2 border border-gray-300 rounded w-full"
              />
            </label>
          </div>
          <div className="flex-1">
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mt-2">
              Start Date:
              <input
                id="startDate"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                className="p-2 border border-gray-300 rounded w-full"
              />
            </label>
          </div>
          <div className="flex-1">
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mt-2">
              End Date:
              <input
                id="endDate"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                className="p-2 border border-gray-300 rounded w-full"
              />
            </label>
          </div>
        </div>
        <div className="w-full mt-4">
          <label htmlFor="leaderboard" className="block text-sm font-medium text-gray-700">
            Select a leaderboard:
            <select
              id="leaderboard"
              value={selectedLeaderboard}
              onChange={handleLeaderboardChange}
              className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Please select</option>
              {leaderboards.map((leaderboard) => (
                <option key={leaderboard.value} value={leaderboard.value}>
                  {leaderboard.label}
                </option>
              ))}
            </select>
          </label>
        </div>
        <div className="w-full mt-4">
          <label htmlFor="multipleParticipants" className="block text-sm font-medium text-gray-700">
            Are there multiple participants in the event?
            <select
              id="multipleParticipants"
              value={multipleParticipants}
              onChange={handleMultipleParticipantsChange}
              className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Please select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </label>
        </div>
        {multipleParticipants && (
        <div className="mt-4 bg-gray-100 p-4 rounded-md">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="participants" className="block text-sm font-medium text-gray-700">Select participants:</label>
          <MultiSelect
            options={users.usersWithLabel}
            value={participants}
            onChange={handleParticipantsChange}
            labelledBy="Select Participants"
          />
        </div>
        )}
        {shouldShowSubmitButton() && (
        <button
          type="submit"
          className="mt-4 bg-green-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-sm"
        >
          Create Event
        </button>
        )}
      </form>
    </div>
  );
}

export default CreateEventSingle;
