import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const UIColorContext = createContext();

function UIColorProvider({ children }) {
  const [colorClasses, setColorClasses] = useState({
    blue: 'bg-blue-500 text-white hover:bg-blue-600 border border-blue-600',
    gray: 'bg-gray-200 text-black hover:bg-gray-300 border border-gray-100',
  });

  const value = useMemo(() => ({ colorClasses, setColorClasses }), [colorClasses]);

  return (
    <UIColorContext.Provider value={value}>
      {children}
    </UIColorContext.Provider>
  );
}

UIColorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UIColorProvider, UIColorContext };
