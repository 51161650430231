import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useUserData } from '../../providers/UserDataProvider';
import useFetch from '../../hooks/useFetch';

function LeaderboardSetupPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchData } = useFetch();
  const [isDefault, setIsDefault] = useState(false);
  const userData = useUserData();
  const isAdmin = userData?.isAdmin || false;

  const [leaderboardConfig, setLeaderboardConfig] = useState({
    name: '',
    positions: [{ position: 1, points: 100 }],
    pointDecrement: 5,
    participationPoints: 0,
    brKillPoints: 0,
    damagePoints: 0,
    tiltLevelSurvivedPoints: 0,
    tiltTopTilteePoints: 0,
    checkpointPoints: 0,
    givePositionPointsOnDNF: false,
    multiplePositionsToAdd: 1,
    gameModes: {
      racing: false,
      battleRoyale: false,
      tilted: false,
    },
  });

  const [initialConfig, setInitialConfig] = useState(null);
  const [isConfigValid, setIsConfigValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const positionsEndRef = useRef(null);

  useEffect(() => {
    const loadLeaderboardConfig = async () => {
      if (id) {
        try {
          const data = await fetchData(`/api/events/leaderboard-config/${id}`);
          if (data) {
            const parsedConfig = JSON.parse(data.config);
            setLeaderboardConfig(parsedConfig);
            setInitialConfig(parsedConfig);
            setIsDefault(data.isDefault);
          }
        } catch (error) {
          console.error('Error loading leaderboard config:', error);
        }
      }
    };

    loadLeaderboardConfig();
  }, [id, fetchData]);

  useEffect(() => {
    // Check if the config is valid (e.g., name is not empty and at least one game mode is selected)
    const isValid = leaderboardConfig.name.trim() !== ''
      && Object.values(leaderboardConfig.gameModes).some((mode) => mode);
    setIsConfigValid(isValid);
  }, [leaderboardConfig]);

  const updateConfig = (key, value) => {
    setLeaderboardConfig((prev) => ({ ...prev, [key]: value }));
  };

  const handlePositionChange = (index, value) => {
    const newPositions = [...leaderboardConfig.positions];
    newPositions[index].points = parseInt(value, 10);
    updateConfig('positions', newPositions);
  };

  const handleAddMultiplePositions = (count) => {
    const newPositions = [...leaderboardConfig.positions];
    const startPosition = newPositions.length + 1;
    const lastPoints = newPositions[newPositions.length - 1].points;

    for (let i = 0; i < count; i += 1) {
      const newPosition = startPosition + i;
      const newPoints = lastPoints - (i + 1) * leaderboardConfig.pointDecrement;
      newPositions.push({ position: newPosition, points: newPoints });
    }

    updateConfig('positions', newPositions);
  };

  const handleRemovePosition = (indexToRemove) => {
    if (leaderboardConfig.positions.length > 1) {
      const newPositions = leaderboardConfig.positions
        .filter((_, index) => index !== indexToRemove)
        .map((pos, index) => ({ ...pos, position: index + 1 }));
      updateConfig('positions', newPositions);
    }
  };

  const handleGameModeChange = (mode) => {
    updateConfig('gameModes', {
      ...leaderboardConfig.gameModes,
      [mode]: !leaderboardConfig.gameModes[mode],
    });
  };

  const handleSave = async () => {
    try {
      setErrorMessage(''); // Clear any previous error messages
      const response = await fetchData('/api/events/leaderboard-config', {
        method: 'POST',
        body: {
          id,
          config: leaderboardConfig,
        },
      });

      if (response && response.id) {
        setInitialConfig(response.config);
        navigate('/my-events');
      } else {
        console.error('Unexpected server response:', response);
        setErrorMessage(response?.message || 'Unexpected response from server');
      }
    } catch (error) {
      console.error('Error saving leaderboard config:', error);
      setErrorMessage(error.message || 'An error occurred while saving');
      // Log additional error details if available
      if (error.response) {
        console.error('Error response:', error.response);
      }
    }
  };

  const hasChanges = () => JSON.stringify(leaderboardConfig) !== JSON.stringify(initialConfig);

  const showSaveButton = isConfigValid && hasChanges() && (isAdmin || !isDefault);
  const disableInputs = isDefault && !isAdmin;

  return (
    <div className="container mx-auto p-4">
      <div className="relative flex items-center justify-center mb-4">
        <Link
          to="/my-events"
          className="absolute left-0 bg-blue-500 text-white px-4 py-2 rounded"
        >
          Go Back to Event Lists
        </Link>
        <h1 className="text-2xl font-bold text-white">Leaderboard Setup</h1>
        {showSaveButton && (
          <button
            type="button"
            onClick={handleSave}
            className="absolute right-0 bg-green-500 text-white px-4 py-2 rounded"
          >
            Save
          </button>
        )}
      </div>
      {errorMessage && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {errorMessage}
        </div>
      )}
      <div className="flex">
        <div className="w-1/4 m-h-[70vh] h-[70vh] overflow-y-auto mb-4 bg-gray-600 border rounded-md p-2 m-1 text-white">
          <div className="mb-4">
            <label htmlFor="leaderboard-name" className="text-xl font-semibold mb-2 mt-4">
              Leaderboard Name
              <input
                type="text"
                id="leaderboard-name"
                value={leaderboardConfig.name}
                onChange={(e) => updateConfig('name', e.target.value)}
                className="mt-4 block w-full border text-black border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Enter leaderboard name"
                disabled={disableInputs}
              />
            </label>
          </div>
          <h2 className="text-xl font-semibold mb-2 mt-4">Game Mode Selection</h2>
          <div className="mb-2">
            <label htmlFor="racing" className="flex items-center">
              <input
                id="racing"
                type="checkbox"
                checked={leaderboardConfig.gameModes.racing}
                onChange={() => handleGameModeChange('racing')}
                className="mr-2"
                disabled={disableInputs}
              />
              Racing
            </label>
          </div>
          <div className="mb-2">
            <label htmlFor="battleRoyale" className="flex items-center">
              <input
                id="battleRoyale"
                type="checkbox"
                checked={leaderboardConfig.gameModes.battleRoyale}
                onChange={() => handleGameModeChange('battleRoyale')}
                className="mr-2"
                disabled={disableInputs}
              />
              Battle Royale
            </label>
          </div>
          <div className="mb-2">
            <label htmlFor="tilted" className="flex items-center">
              <input
                id="tilted"
                type="checkbox"
                checked={leaderboardConfig.gameModes.tilted}
                onChange={() => handleGameModeChange('tilted')}
                className="mr-2"
                disabled={disableInputs}
              />
              Tilted
            </label>
          </div>
        </div>
        <div className="w-1/4 m-h-[70vh] h-[70vh] overflow-y-auto mb-4 bg-gray-600 border rounded-md p-2 m-1 text-white">
          <h2 className="text-xl font-semibold mb-2">Position Points</h2>
          <TransitionGroup>
            {leaderboardConfig.positions.map((pos, index) => (
              <CSSTransition key={pos.position} timeout={300} classNames="item">
                <div className="flex mb-2 items-center">
                  <label htmlFor={`position-${pos.position}`} className="w-1/2 mr-2">
                    Position {pos.position}:
                  </label>
                  <div className="flex-grow flex items-center">
                    <input
                      id={`position-${pos.position}`}
                      type="number"
                      value={pos.points}
                      onChange={(e) => handlePositionChange(index, e.target.value)}
                      className="w-full p-1 border rounded-l text-black"
                      disabled={disableInputs}
                    />
                    {leaderboardConfig.positions.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemovePosition(index)}
                        className="bg-red-500 text-white px-2 py-1 rounded-r border border-red-500"
                        disabled={disableInputs}
                      >
                        X
                      </button>
                    )}
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
          <div ref={positionsEndRef} />
        </div>
        <div className="w-1/4 m-h-[70vh] h-[70vh] overflow-y-auto mb-4 bg-gray-600 border rounded-md p-2 m-1 text-white">
          <div className="">
            <h2 className="text-xl font-semibold mb-2">Add Multiple Positions</h2>
            <div className="flex items-center mb-2">
              <input
                type="number"
                min="1"
                value={leaderboardConfig.multiplePositionsToAdd}
                onChange={(e) => updateConfig('multiplePositionsToAdd', parseInt(e.target.value, 10))}
                className="w-20 p-1 border rounded mr-2 text-black"
                disabled={disableInputs}
              />
              <button
                type="button"
                onClick={() => handleAddMultiplePositions(leaderboardConfig.multiplePositionsToAdd)}
                className="bg-green-500 text-white px-4 py-1 rounded"
                disabled={disableInputs}
              >
                Add Positions
              </button>
              <button
                type="button"
                onClick={() => updateConfig('positions', [leaderboardConfig.positions[0]])}
                className="bg-red-500 text-white ml-1 px-4 py-1 rounded"
                disabled={disableInputs}
              >
                Clear
              </button>
            </div>
            <div className="mb-2">
              <label htmlFor="point-decrement">
                Point Decrement Per Placement Added:
                <input
                  id="point-decrement"
                  type="number"
                  value={leaderboardConfig.pointDecrement}
                  onChange={(e) => updateConfig('pointDecrement', parseInt(e.target.value, 10))}
                  className="w-full p-1 border rounded text-black"
                  disabled={disableInputs}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="w-1/4 m-h-[70vh] h-[70vh] overflow-y-auto mb-4 bg-gray-600 border rounded-md p-2 m-1 text-white">
          <h2 className="text-xl font-semibold mb-2">Point Settings</h2>
          <div className="mb-2">
            <label htmlFor="dnf-points" className="block mb-1">
              Give Position Points on DNF:
              <select
                id="dnf-points"
                value={leaderboardConfig.givePositionPointsOnDNF}
                onChange={(e) => updateConfig('givePositionPointsOnDNF', e.target.value === 'true')}
                className="w-full p-1 border rounded mt-1 text-black"
                disabled={disableInputs}
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>
            </label>
          </div>
          <div className="mb-2">
            <label htmlFor="participation-points">
              Participation Points Per Race:
              <input
                id="participation-points"
                type="number"
                value={leaderboardConfig.participationPoints}
                onChange={(e) => updateConfig('participationPoints', parseInt(e.target.value, 10))}
                className="w-full p-1 border rounded text-black"
                disabled={disableInputs}
              />
            </label>
          </div>
          {leaderboardConfig.gameModes.racing && (
            <div className="mb-2">
              <label htmlFor="checkpoint-points">
                Points Per Checkpoint:
                <input
                  id="checkpoint-points"
                  type="number"
                  value={leaderboardConfig.checkpointPoints}
                  onChange={(e) => updateConfig('checkpointPoints', parseInt(e.target.value, 10))}
                  className="w-full p-1 border rounded text-black"
                  disabled={disableInputs}
                />
              </label>
            </div>
          )}
          {leaderboardConfig.gameModes.battleRoyale && (
            <>
              <div className="mb-2">
                <label htmlFor="br-kill-points">
                  Points Per Battle Royale Kill:
                  <input
                    id="br-kill-points"
                    type="number"
                    value={leaderboardConfig.brKillPoints}
                    onChange={(e) => updateConfig('brKillPoints', parseInt(e.target.value, 10))}
                    className="w-full p-1 border rounded text-black"
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="damage-points">
                  Points Per Battle Royale Damage:
                  <input
                    id="damage-points"
                    type="number"
                    value={leaderboardConfig.damagePoints}
                    onChange={(e) => updateConfig('damagePoints', parseInt(e.target.value, 10))}
                    className="w-full p-1 border rounded text-black"
                    disabled={disableInputs}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="crown-points">
                  Points Per Battle Royale Crown Win:
                  <input
                    id="crown-points"
                    type="number"
                    value={leaderboardConfig.crownWinPoints}
                    onChange={(e) => updateConfig('crownWinPoints', parseInt(e.target.value, 10))}
                    className="w-full p-1 border rounded text-black"
                    disabled={disableInputs}
                  />
                </label>
              </div>
            </>
          )}
          {leaderboardConfig.gameModes.tilted && (
            <>
              <div className="mb-2">
                <label htmlFor="tilt-level-survived-points">
                  Tilt Level Survived Points:
                  <input
                    id="tilt-level-survived-points"
                    type="number"
                    value={leaderboardConfig.tiltLevelSurvivedPoints}
                    onChange={(e) => updateConfig('tiltLevelSurvivedPoints', parseInt(e.target.value, 10))}
                    className="w-full p-1 border rounded text-black"
                    disabled={disableInputs}
                  />
                </label>
              </div>
              <div className="mb-2">
                <label htmlFor="tilt-top-tiltee-points">
                  Tilt Top Tiltee Points:
                  <input
                    id="tilt-top-tiltee-points"
                    type="number"
                    value={leaderboardConfig.tiltTopTilteePoints}
                    onChange={(e) => updateConfig('tiltTopTilteePoints', parseInt(e.target.value, 10))}
                    className="w-full p-1 border rounded text-black"
                    disabled={disableInputs}
                  />
                </label>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default LeaderboardSetupPage;
