import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import Button from '../UIComponents/Button';

function Dashboard() {
  const [activeTab, setActiveTab] = useState('Season');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const validPaths = ['/dashboard/lifetime', '/dashboard/channel', '/dashboard/season'];
    const channelRegex = /^\/dashboard\/channel\/\w+$/;
    const seasonRegex = /^\/dashboard\/season\/\w+$/;
    const newSeasonRegex = /^\/dashboard\/channel\/\w+\/\w+$/;

    if (
      !validPaths.includes(location.pathname)
      && (
        !channelRegex.test(location.pathname)
        && !seasonRegex.test(location.pathname)
        && !newSeasonRegex.test(location.pathname)
      )
    ) {
      navigate('/dashboard/season');
    } else {
      const tab = location.pathname.split('/').pop();
      setActiveTab(tab.charAt(0).toUpperCase() + tab.slice(1));
    }
  }, [location, navigate]);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Welcome to the Dashboard!</h1>
      <div className="flex space-x-4 mb-4">
        <Button
          color={activeTab === 'Season' ? 'blue' : 'gray'}
          onClick={() => {
            setActiveTab('Season');
            navigate('/dashboard/season');
          }}
        >
          Season
        </Button>
        <Button
          color={activeTab === 'Channel' ? 'blue' : 'gray'}
          onClick={() => {
            setActiveTab('Channel');
            navigate('/dashboard/channel');
          }}
        >
          Lifetime by Channel
        </Button>
      </div>
      <div className="p-4 border rounded bg-gray-600 shadow-lg">
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
