import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear } from '@fortawesome/free-solid-svg-icons';

function NotFoundPage() {
  useEffect(() => {
    document.title = 'MyStats - Not Found'; // Set the page title here
  }, []);

  return (
    <div className="flex justify-center items-center h-[91vh] bg-gray-600 border-2 text-white rounded-md">
      <div className="text-center">
        <FontAwesomeIcon icon={faSadTear} className="text-6xl text-white mb-4" />
        <h1 className="text-3xl font-bold text-gray-900 mb-2">404 - Page Not Found</h1>
        <p className="text-gray-300">Sorry, the page you are looking for does not exist.</p>
      </div>
    </div>
  );
}

export default NotFoundPage;
