import React, { useState, useEffect, useCallback } from 'react';
import {
  Link, useNavigate, useLocation, Outlet,
} from 'react-router-dom';
import { UserDataProvider } from '../providers/UserDataProvider';
import useFetch from '../hooks/useFetch';

function InternalLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState(() => {
    const storedData = localStorage.getItem('userData');
    return storedData ? JSON.parse(storedData) : null;
  });
  const { fetchData, error } = useFetch();
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);

  const toggleAdminMenu = () => {
    setIsAdminMenuOpen((prev) => !prev);
  };

  const refreshUserData = useCallback(async () => {
    try {
      const freshData = await fetchData('/api/user');
      if (freshData) {
        setUserData(freshData);
        localStorage.setItem('userData', JSON.stringify(freshData));
      }
    } catch (err) {
      console.error('Error refreshing user data:', err);
    }
  }, [fetchData]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/login');
    } else {
      refreshUserData();
    }
  }, [location, navigate, refreshUserData]);

  useEffect(() => {
    const refreshInterval = setInterval(refreshUserData, 5 * 60 * 1000); // Refresh every 5 minutes
    return () => clearInterval(refreshInterval);
  }, [refreshUserData]);

  if (error) {
    return <div>Error: {error.error}</div>;
  }

  return (
    <div className="flex bg-slate-400 h-auto min-h-screen">
      <header className="flex items-center border-b-2 border-white justify-between bg-zinc-800 text-white p-4 w-full fixed top-0 left-0 h-12 z-10">
        <div className="flex items-center">
          {/* Empty div to push the user info to the right */}
        </div>
        <div className="flex items-center">
          {userData ? (
            <img src={userData.profile_image_url} alt="Profile" className="h-8 w-8 rounded-full mr-2" />
          ) : (
            <div className="bg-gray-300 h-8 w-8 rounded-full mr-2">{}</div>
          )}
          <span className="text-gray-200">
            {userData ? userData.username : 'Loading...'}
          </span>
          <Link to="/logout" className="ml-4">Logout</Link>
        </div>
      </header>
      <div className="flex flex-1 mt-12">
        <aside style={{ top: '2.85rem' }} className="fixed left-0 w-64 bg-zinc-800 text-white pl-4 pr-4 pb-4 h-full overflow-y-auto border-r-2 border-white z-10">
          <img src="/assets/images/mystats_logo.png" alt="Site Logo" className="w-100" />
          <nav className="">
            <Link to="/dashboard" className="block py-2 hover:text-gray-400">Dashboard</Link>
            <Link to="/download" className="block py-2 hover:text-gray-400">Download MyStats</Link>
            <Link to="/my-events" className="block py-2 hover:text-gray-400">My Events</Link>
            <Link to="/leaderboards" className="block py-2 hover:text-gray-400">Streamer Leaderboards</Link>
            {userData && userData.isAdmin && (
              <>
                <button
                  type="button"
                  onClick={toggleAdminMenu}
                  className="block py-2 text-left w-full"
                >
                  Admin Menu
                  {' '}
                  {isAdminMenuOpen ? '▲' : '▼'}
                </button>
                {isAdminMenuOpen && (
                  <div className="pl-4">
                    <Link to="/admin/update-mystats-settings" className="block py-2 hover:text-gray-400">Update MyStats Settings</Link>
                    <Link to="/admin/upload-new-version" className="block py-2 hover:text-gray-400">Upload New Version</Link>
                    <Link to="/admin/create-patch-notes" className="block py-2 hover:text-gray-400">Create Patch Notes</Link>
                    <Link to="/admin/user-reports" className="block py-2 hover:text-gray-400">User Reports</Link>
                    <Link to="/admin/csv-testing" className="block py-2 hover:text-gray-400">CSV Testing</Link>
                    <a href="https://trello.com/b/E9wZxOUQ/my-stats-online" target="_blank" rel="noreferrer" className="block py-2 hover:text-gray-400">Trello Board</a>
                  </div>
                )}
              </>
            )}
          </nav>
        </aside>
        <main style={{ marginTop: '0', marginBottom: '0' }} className="flex-1 p-4 ml-64">
          <UserDataProvider userData={userData} refreshUserData={refreshUserData}>
            <Outlet />
          </UserDataProvider>
        </main>
      </div>
    </div>
  );
}

export default InternalLayout;
