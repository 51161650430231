import React, { useEffect } from 'react';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';
import ChannelList from './ChannelListComponent';

function LifetimeChannelListPage() {
  const { data: channels, loading, error } = useFetchWithAuth('/api/dashboard/channels');

  useEffect(() => {
    document.title = 'MyStats - Dashboard - Channels'; // Set the page title here
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) {
    return (
      <div>
        Error:
        {error.error}
      </div>
    );
  }

  return (
    <div>
      <ChannelList channels={channels} loading={loading} error={error} />
    </div>
  );
}

export default LifetimeChannelListPage;
