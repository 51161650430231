/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faTrophy } from '@fortawesome/free-solid-svg-icons';
import useFetch from '../../hooks/useFetch';
import { useUserData } from '../../providers/UserDataProvider';

function EventPage() {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const { fetchData, loading, error } = useFetch();
  const [events, setEvents] = useState({ eventSeries: [], events: [] });
  const [leaderboards, setLeaderboards] = useState([]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await fetchData('/api/events/getAll');
        setEvents(data);
      } catch (err) {
        console.error('Error fetching events:', error);
      }
    };

    const fetchLeaderboards = async () => {
      try {
        const data = await fetchData('/api/events/leaderboards');
        setLeaderboards(data);
      } catch (err) {
        console.error('Error fetching leaderboards:', err);
      }
    };

    fetchEvents();
    fetchLeaderboards();
  }, [fetchData, error]);

  const shouldShowManageEventButton = (event) => userData.id === event.createdBy;

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!events || !leaderboards) return <div>Loading...</div>;

  return (
    <div className="p-4 m-h-[80vh] h-[80vh]">
      <div className="relative flex justify-center items-center mb-8">
        <h1 className="text-2xl font-bold text-center mx-auto text-white ">My Events</h1>
        <div className="absolute right-0 space-x-2">
          <button
            type="button"
            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          >
            <Link to="/my-events/create">Create Event</Link>
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 ">
        <div className="bg-gray-100 p-3 border border-1 rounded-md m-h-[82vh] h-[82vh] bg-gray-600">
          <h2 className="text-xl font-semibold mb-4 text-white">Event Series</h2>
          <div className="overflow-y-auto m-h-[50vh] h-[74vh]">
            {events.eventSeries.length > 0 ? (
              <ul className="list-none">
                {events.eventSeries.map((series) => (
                  <li key={series.id} className="mb-4 mr-2 p-4 bg-white rounded shadow">
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-semibold">{series.name}</h3>
                        <p className="text-sm text-gray-500">
                          Events:
                          {series.eventCount}
                        </p>
                      </div>
                      <Link className="text-white py-1 px-3 border rounded bg-blue-500 hover:bg-blue-700" to={`/my-events/manage-series/${series.id}`}>
                        <FontAwesomeIcon icon={faCog} />
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No event series available.</p>
            )}
          </div>
        </div>
        <div className="bg-gray-100 p-3 border border-1 rounded-md h-[82vh] bg-gray-600">
          <h2 className="text-xl font-semibold mb-4 text-white">Single Events</h2>
          <div className="overflow-y-auto m-h-[50vh] h-[74vh] hover:scrollbar-thumb-sky-500 active:scrollbar-thumb-sky-400 scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300 overflow-y-scroll">
            {events.events.length > 0 ? (
              <ul className="list-none">
                {events.events.map((event) => (
                  <li key={event.id} className="mb-2 mr-2 bg-white shadow overflow-hidden rounded-md px-4 py-4">
                    <div className="flex justify-between items-start">
                      <div>
                        <p className="text-lg font-semibold">{event.name}</p>
                        <p className="text-sm text-gray-500">Created by {event.creator.username}</p>
                      </div>
                      <div className="flex space-x-2">
                        <button
                          type="button"
                          className={`bg-green-500 hover:bg-green-700 text-white font-bold p-2 rounded relative group ${!event.hasResults ? 'opacity-50 cursor-not-allowed' : ''}`}
                          onClick={() => navigate(`/my-events/view-leaderboard/${event.id}`)}
                          title={event.hasResults ? 'View Leaderboard' : 'Event has no results'}
                          disabled={!event.hasResults}
                        >
                          <FontAwesomeIcon icon={faTrophy} />
                        </button>
                        {shouldShowManageEventButton(event) && (
                          <button
                            type="button"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
                            onClick={() => navigate(`/my-events/manage/${event.id}`)}
                            title="Manage Event"
                          >
                            <FontAwesomeIcon icon={faCog} />
                          </button>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">You are not currently participating in any events.</p>
            )}
          </div>
        </div>
        <div className="bg-gray-100 p-3 border border-1 rounded-md m-h-[82vh] h-[82vh] overflow-y-auto bg-gray-600">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold text-white">Leaderboard Configurations</h2>
            <Link
              to="/new-leaderboard-config"
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded"
            >
              New Config
            </Link>
          </div>
          <div className="overflow-y-auto m-h-[50vh] h-[74vh]">

            {leaderboards.length > 0 ? (
              <ul className="list-none">
                {leaderboards.map((leaderboard) => (
                  <li key={leaderboard.id} className="mb-4 p-4 bg-white rounded shadow">
                    <div className="flex justify-between items-center">
                      <div>
                        <h3 className="text-lg font-semibold">{leaderboard.name}</h3>
                      </div>
                      <Link
                        to={`/manage-leaderboard-config/${leaderboard.id}`}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                      >
                        Manage
                      </Link>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-500">No leaderboards available.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventPage;
