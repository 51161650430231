import React, { useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useNavigate } from 'react-router-dom';
import useFetchWithAuth from '../../../hooks/useFetchWithAuth';

function CreateEventSeries() {
  const {
    data: users, loading, error, post,
  } = useFetchWithAuth('/api/dropdowns/users');
  const {
    data: leaderboards, loading: leaderboardLoading, error: leaderboardError,
  } = useFetchWithAuth('/api/dropdowns/leaderboards');
  const [multipleStreamers, setMultipleStreamers] = useState('');
  const [sameStreamers, setSameStreamers] = useState('');
  const [selectedStreamers, setSelectedStreamers] = useState([]);
  const [events, setEvents] = useState([]);
  const [seriesName, setSeriesName] = useState('');
  const [sameLeaderboard, setSameLeaderboard] = useState('');
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('');
  const navigate = useNavigate();

  const handleSeriesNameChange = (e) => {
    setSeriesName(e.target.value);
  };

  const handleMultipleStreamersChange = (e) => {
    setMultipleStreamers(e.target.value === 'true');
    if (e.target.value === 'false') {
      setSelectedStreamers([]);
      setEvents(events.map((item) => ({ ...item, participants: [] })));
    }
  };

  const handleSameStreamersChange = (e) => {
    const value = e.target.value === 'true';
    setSameStreamers(value);
    if (value) {
      setEvents(events.map((item) => ({ ...item, participants: selectedStreamers })));
    } else {
      setEvents(events.map((item) => ({ ...item, participants: selectedStreamers })));
    }
  };

  const handleSameLeaderboardChange = (e) => {
    const value = e.target.value === 'true';
    setSameLeaderboard(value);
    if (value) {
      setEvents(events.map((item) => ({ ...item, leaderboard: Number(selectedLeaderboard) })));
    } else {
      setEvents(events.map((item) => ({ ...item, leaderboard: [] })));
    }
  };

  const handleAddEvent = () => {
    const event = {
      name: '',
      startDate: '',
      endDate: '',
      participants: sameStreamers ? selectedStreamers : [],
      leaderboard: sameLeaderboard ? selectedLeaderboard : '',
    };
    setEvents([...events, event]);
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleCopyForWeekEvent = () => {
    if (events.length === 0) return; // Check if there are any events to copy
    const lastEvent = events[events.length - 1]; // Get the last event added
    const nextWeek = new Date(lastEvent.endDate); // Use the end date of the last event
    nextWeek.setDate(nextWeek.getDate() + 7); // Move to the next week
    const nextWeekEvents = events.map((event) => {
      let eventName = event.name;
      if (eventName.includes('Week')) {
        const weekNumber = parseInt(eventName.split('Week ')[1], 10) + 1; // Added radix parameter
        eventName = `${eventName.split('Week ')[0]}Week ${weekNumber}`; // Use template literals
      }
      return {
        ...event,
        name: eventName,
        startDate: nextWeek.toISOString().split('T')[0],
        endDate: nextWeek.toISOString().split('T')[0],
      };
    });
    setEvents([...events, ...nextWeekEvents]);
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  const handleSelectedStreamersChange = (e) => {
    setSelectedStreamers(e);
    if (sameStreamers) {
      setEvents(events.map((item) => ({ ...item, participants: e })));
    }
  };

  const handleSelectedLeaderboardChange = (e) => {
    console.log('Selected Leaderboard:', e.target.value);
    setSelectedLeaderboard(e.target.value);
    if (sameLeaderboard) {
      setEvents(events.map((item) => ({ ...item, leaderboard: Number(e.target.value) })));
    }
  };

  const handleEventNameChange = (e, index) => {
    const updatedEvents = [...events];
    if (!updatedEvents[index]) {
      updatedEvents[index] = {
        name: '', startDate: '', endDate: '', participants: [], leaderboard: [],
      };
    }
    updatedEvents[index].name = e.target.value;
    console.log('updatedEvents:', updatedEvents);
    setEvents(updatedEvents);
  };

  const handleStartDateChange = (e, index) => {
    const updatedEvents = [...events];
    updatedEvents[index].startDate = e.target.value;
    if (events[index].endDate === '') {
      updatedEvents[index].endDate = e.target.value;
    }
    if (events[index].endDate < e.target.value) {
      updatedEvents[index].endDate = e.target.value;
    }
    setEvents(updatedEvents);
  };

  const handleEndDateChange = (e, index) => {
    const updatedEvents = [...events];
    updatedEvents[index].endDate = e.target.value;
    if (events[index].startDate === '') {
      updatedEvents[index].startDate = e.target.value;
    }
    if (events[index].startDate > e.target.value) {
      updatedEvents[index].startDate = e.target.value;
    }
    setEvents(updatedEvents);
  };

  const handleRemoveEvent = (index) => {
    const updatedEvents = [...events];
    updatedEvents.splice(index, 1);
    setEvents(updatedEvents);
  };

  const handleEventUsersChange = (index, selectedUsers) => {
    const updatedEvents = [...events];
    updatedEvents[index].participants = selectedUsers;
    setEvents(updatedEvents);
  };

  const handleEventLeaderboardChange = (index, thisSelectedLeaderboard) => {
    const updatedEvents = [...events];
    updatedEvents[index].leaderboard = thisSelectedLeaderboard;
    setEvents(updatedEvents);
  };

  const areAllEventsValid = () => events.length === 0
    || (events.length > 0 && events.every((event) => {
      const hasRequiredFields = event.name && event.startDate && event.endDate;
      let hasParticipants = true;
      let hasLeaderboard = true;
      if (multipleStreamers) {
        hasParticipants = sameStreamers
          ? selectedStreamers.length >= 1
          : event.participants && event.participants.length >= 1;
      }
      if (sameLeaderboard) {
        hasLeaderboard = sameLeaderboard
          ? selectedLeaderboard > 0
          : event.leaderboard && event.leaderboard > 0;
      }
      return hasRequiredFields && hasParticipants && hasLeaderboard;
    }));

  const shouldShowAddEventButton = () => (
    ((multipleStreamers && sameStreamers && selectedStreamers.length > 1
      && sameLeaderboard && selectedLeaderboard > 0)
      || (
        multipleStreamers && !sameStreamers && (
          (sameLeaderboard && selectedLeaderboard > 0) || !sameLeaderboard
        ) && areAllEventsValid()
      )
      || (
        multipleStreamers && sameStreamers && selectedStreamers.length > 1 && !sameLeaderboard
      )) && areAllEventsValid()
  );

  const shouldShowSubmitButton = () => seriesName
    && events.length > 0
    && seriesName.length >= 3
    && areAllEventsValid();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        seriesName,
        events,
      };
      const response = await post(formData, '/api/events/createEventSeries');
      if (response.status === 'success') {
        navigate('/my-events');
      }
    } catch (submitError) {
      // empty
    }
  };

  if (loading || leaderboardLoading) return <div>Loading...</div>;

  if (error || leaderboardError) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  return (
    <div>
      <form onSubmit={HandleSubmit}>
        <div className="w-full">
          <label htmlFor="seriesName" className="block text-sm font-medium text-gray-700 mt-2">
            Series Name:
            <input
              id="seriesName"
              type="text"
              value={seriesName}
              onChange={handleSeriesNameChange}
              className="p-2 border border-gray-300 rounded w-full"
            />
          </label>
        </div>

        <label htmlFor="multipleStreamers" className="block text-sm font-medium text-gray-700 mt-4">
          Are there multiple streamers participating in the event?
          <select
            id="multipleStreamers"
            value={multipleStreamers}
            onChange={handleMultipleStreamersChange}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Please select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        {multipleStreamers === true && (
          <div className="mt-4 bg-gray-100 p-4 rounded-md">
            <label htmlFor="sameStreamers" className="block text-sm font-medium text-gray-700">
              Will the same streamers participate in each event?
              <select
                id="sameStreamers"
                value={sameStreamers}
                onChange={handleSameStreamersChange}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Please select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </label>

          </div>
        )}

        {sameStreamers && (
          <div className="mt-4 bg-gray-100 p-4 rounded-md">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label className="block text-sm font-medium text-gray-700">Select participating streamers:</label>
            <MultiSelect
              options={users.usersWithLabel}
              value={selectedStreamers}
              onChange={handleSelectedStreamersChange}
              labelledBy="Select Streamers"
            />
          </div>
        )}

        <label htmlFor="sameLeaderboard" className="block text-sm font-medium text-gray-700 mt-4">
          Will the same leaderboard be used for all events?
          <select
            id="sameLeaderboard"
            value={sameLeaderboard}
            onChange={handleSameLeaderboardChange}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Please select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>

        {sameLeaderboard && (
          <div className="mt-4 bg-gray-100 p-4 rounded-md">
            <label htmlFor="sameLeaderboard" className="block text-sm font-medium text-gray-700">
              Select leaderboard:
              <select
                id="sameLeaderboard"
                value={selectedLeaderboard}
                onChange={handleSelectedLeaderboardChange}
                className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Please select</option>
                {leaderboards.map((leaderboard) => (
                  <option key={leaderboard.value} value={leaderboard.value}>
                    {leaderboard.label.substring(0, 100)}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}

        {events.map((event, index) => (
          <div key={event.id} className="relative mt-4 bg-gray-50 p-4 rounded-md shadow-sm">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              type="button"
              onClick={() => handleRemoveEvent(index)}
              className="absolute top-1 right-1 bg-red-500 text-white py-1 px-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <i className="fas fa-times" />
            </button>
            <div className="flex flex-wrap -mx-2">
              <div className="w-full md:w-1/3 px-2">
                <label htmlFor="eventName" className="block text-sm font-medium text-gray-700 mt-2">
                  Event Name:
                  <input
                    id="eventName"
                    type="text"
                    value={event.name}
                    onChange={(e) => handleEventNameChange(e, index)}
                    className="p-2 border border-gray-300 rounded w-full"
                  />
                </label>

              </div>
              <div className="w-full md:w-1/3 px-2">
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mt-2">
                  Start Date:
                  <input
                    id="startDate"
                    type="date"
                    value={event.startDate}
                    onChange={(e) => handleStartDateChange(e, index)}
                    className="p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
              <div className="w-full md:w-1/3 px-2">
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mt-2">
                  End Date:
                  <input
                    id="endDate"
                    type="date"
                    value={event.endDate}
                    onChange={(e) => handleEndDateChange(e, index)}
                    className="p-2 border border-gray-300 rounded w-full"
                  />
                </label>
              </div>
            </div>
            {sameStreamers === false && (
              <div className="mt-4 bg-gray-100 p-4 rounded-md">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="block text-sm font-medium text-gray-700">Select participating streamers for this event:</label>
                <MultiSelect
                  options={users.usersWithLabel}
                  value={sameStreamers ? selectedStreamers : event.participants}
                  onChange={(selectusers) => handleEventUsersChange(index, selectusers)}
                  labelledBy="Select Users"
                />
              </div>
            )}
            {sameLeaderboard === false && (
              <div className="mt-4 bg-gray-100 p-4 rounded-md">
                <label htmlFor={`sameLeaderboard-${index}`} className="block text-sm font-medium text-gray-700">
                  Select leaderboard for this event:
                  <select
                    id={`sameLeaderboard-${index}`}
                    value={sameLeaderboard ? selectedLeaderboard : event.leaderboard}
                    onChange={(e) => handleEventLeaderboardChange(index, e.target.value)}
                    className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Please select</option>
                    {leaderboards.map((leaderboard) => (
                      <option key={leaderboard.value} value={leaderboard.value}>
                        {leaderboard.label.substring(0, 100)}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            )}
          </div>
        ))}
        <div className="relative min-h-10">
          <hr className="border border-10 border-black-900" />
          {
            (shouldShowAddEventButton()) && (
              <>
                <button
                  type="button"
                  onClick={handleAddEvent}
                  className="mt-4 mr-2 bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm"
                >
                  {events.length > 0 ? 'Add Another Event' : 'Add Event'}
                </button>
                {false && events.length > 0 && (
                  <button
                    type="button"
                    onClick={handleCopyForWeekEvent}
                    className="mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm"
                  >
                    Add For Following Week
                  </button>
                )}
              </>
            )
          }

          {shouldShowSubmitButton() && (
            <button
              type="submit"
              className="absolute right-1 ml-4 mt-4 bg-green-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 text-sm"
            >
              Create Series
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default CreateEventSeries;
