import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * @typedef {Object} FetchOptions
 * @property {'GET' | 'POST' | 'PUT' | 'DELETE'} [method]
 * @property {any} [body]
 * @property {boolean} [isBlob] - Indicates if the response should be treated as a Blob
 */

/**
 * Custom hook for making API requests
 * @returns {{fetchData: function, loading: boolean, error: Error|null}}
 */
const useFetch = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  /**
   * @template T
   * @param {string} url
   * @param {FetchOptions} [options={}]
   * @returns {Promise<T|null>}
   */
  const fetchData = useCallback(async (url, options = {}) => {
    if (!options.isBlob) { // Only set loading if not a Blob request
      setLoading(true);
    }
    setError(null);

    const jwtToken = localStorage.getItem('jwtToken');
    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    try {
      const method = options.method || 'GET';
      const headers = {
        'Content-Type': 'application/json', // Default to JSON
        Authorization: jwtToken ? `Bearer ${jwtToken}` : '',
      };

      const fetchOptions = {
        method,
        headers,
      };

      // Only add body for non-GET requests
      if (method !== 'GET' && options.body) {
        if (options.body instanceof FormData) {
          delete headers['Content-Type']; // Remove Content-Type for FormData
          fetchOptions.body = options.body; // Directly use the FormData object
        } else {
          fetchOptions.body = JSON.stringify(options.body); // Stringify for JSON
        }
      }

      const response = await fetch(`${baseUrl}${url}`, fetchOptions);

      if (response.status === 401) {
        navigate('/unauthorized', { state: { from: url } }); // Redirect to unauthorized page
        return { unauthorized: true }; // Indicate unauthorized access
      }

      if (response.status === 403) {
        return { unauthorized: true }; // Indicate unauthorized access
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Check if the response should be treated as a Blob
      if (options.isBlob) {
        return await response.blob(); // Return the Blob directly
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      setError(err instanceof Error ? err : new Error('An unknown error occurred'));
      return null;
    } finally {
      if (!options.isBlob) { // Only reset loading if not a Blob request
        setLoading(false);
      }
    }
  }, [navigate]);

  return { fetchData, loading, error };
};

export default useFetch;
