import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const changeLanguageHandler = useCallback((lang) => {
    i18n.changeLanguage(lang);
  }, [i18n]);

  useEffect(() => {
    const token = localStorage.getItem('jwtToken');

    const setPageTitle = () => {
      document.title = t('loginpage.pageTitle'); // Set the page title here
    };

    if (token) {
      // navigate('dashboard');
      setLoading(false);
    } else {
      changeLanguageHandler('en');
      setPageTitle();
      setLoading(false);
    }
  }, [changeLanguageHandler, t, navigate]);

  const handleLogin = () => {
    const clientId = 'qujh30k3kqhq1fclwrqn0gsvzl92ey';
    const redirectUri = `${process.env.REACT_APP_LOGIN_BASE_URL}/auth/callback`;
    const scope = 'user:read:email';
    const twitchAuthUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=token&scope=${scope}`;
    window.location.href = twitchAuthUrl;
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen">
        <p className="text-white bg-black border border-white p-1">Attempting to login...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/images/background.jpg')" }}>
      <button
        type="button"
        className="bg-purple-600 text-white py-2 px-10 rounded hover:bg-purple-700"
        onClick={handleLogin}
      >
        {t('loginPage.loginButton')}
      </button>
    </div>
  );
}

export default LoginPage;
