import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CreateEventSeries from './components/CreateEventSeries';
import CreateEventSingle from './components/CreateEventSingle';

function CreateEventPage() {
  const [addToSeries, setAddToSeries] = useState('');
  const [createSeries, setCreateSeries] = useState('');

  const handleAddToSeriesChange = (event) => {
    setAddToSeries(event.target.value);
  };

  const handleCreateSeriesChange = (event) => {
    setCreateSeries(event.target.value);
  };

  return (
    <div>
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">Create Event</h1>
      </div>
      <div className="bg-gray-100 p-4 rounded-md">
        <label htmlFor="addToSeries" className="block text-sm font-medium text-gray-700">
          Add to existing series:
          <select
            id="addToSeries"
            value={addToSeries}
            onChange={handleAddToSeriesChange}
            className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Please select</option>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </label>
      </div>

      {addToSeries === 'true' && (
        <div className="mt-4">
          <p className="text-gray-700">
            If you&apos;re adding to an existing series, click
            {' '}
            <Link to="/my-events" className="text-indigo-600 hover:text-indigo-900">
              here
            </Link>
            {' '}
            to manage your events.
          </p>
        </div>
      )}

      {addToSeries === 'false' && (
        <div className="mt-4 bg-gray-100 p-4 rounded-md">
          <label htmlFor="createSeries" className="block text-sm font-medium text-gray-700">
            Create a series:
            <select
              id="createSeries"
              value={createSeries}
              onChange={handleCreateSeriesChange}
              className="block w-full mt-1 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              <option value="">Please select</option>
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </label>
          {createSeries !== '' && (
          <div className="mt-4 bg-gray-200 p-4 rounded-md">
            {createSeries === 'true' ? (
              <CreateEventSeries />
            ) : (
              <CreateEventSingle />
            )}
          </div>
          )}
        </div>
      )}
    </div>
  );
}

export default CreateEventPage;
