import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

function UnauthorizedPage() {
  return (
    <div className="flex justify-center items-center h-[91vh] bg-gray-600 border-2 text-white rounded-md">
      <div className="text-center">
        <FontAwesomeIcon icon={faExclamationTriangle} className="text-6xl text-white mb-4" />
        <h1 className="text-3xl font-bold text-gray-900 mb-2">401 - Unauthorized Access</h1>
        <p className="text-gray-300">You do not have permission to access this page.</p>
      </div>
    </div>
  );
}

export default UnauthorizedPage;
