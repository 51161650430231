import React from 'react';
import { Link } from 'react-router-dom';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';

function SeasonStatsPage() {
  const { data, loading, error } = useFetchWithAuth('/api/dashboard/get-user-seasons/');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error.error}
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <div>No Data Available</div>;
  }

  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-1" key="lifetime">
          <div className="bg-white rounded-lg shadow-lg p-4 text-center">
            <Link to="/dashboard/lifetime" className="text-blue-500 hover:underline">
              <h3 className="text-xl font-bold">Lifetime</h3>
            </Link>
            <div className="text-gray-500 grid grid-cols-2">
              <div className="p-2">
                <p className="font-semibold">Races</p>
                <p>{data.lifetime.totalRaces}</p>
              </div>
              <div className="p-2">
                <p className="font-semibold">Points</p>
                <p>{data.lifetime.totalPoints}</p>
              </div>
            </div>
          </div>
        </div>
        {data.seasons.map((season) => (
          <div className="col-span-1" key={season.value}>
            <div className="bg-white rounded-lg shadow-lg p-4 text-center">
              <Link to={`/dashboard/season/${season.value}`} className="text-blue-500 hover:underline">
                <h3 className="text-xl font-bold">{season.name}</h3>
              </Link>
              <div className="text-gray-500 grid grid-cols-2">
                <div className="p-2">
                  <p className="font-semibold">Races</p>
                  <p>{season.totalRaces}</p>
                </div>
                <div className="p-2">
                  <p className="font-semibold">Points</p>
                  <p>{season.totalPoints}</p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SeasonStatsPage;
