import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

function ExternalLayout({ children }) {
  return (
    <div className="flex flex-col min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/images/mystats_bg.png')" }}>
      <main className="flex-grow flex flex-col items-center justify-center text-center p-4">
        {children}
      </main>
      <footer className="bg-gray-800 text-white p-4 text-center">
        <p>Footer Content</p>
      </footer>
    </div>
  );
}

ExternalLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ExternalLayout;
