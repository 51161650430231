import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';
import ChannelStatsComponent from './ChannelStatsComponent';

function LifetimeChannelStatsPage() {
  // Get the channel name from the URL
  const { channelName } = useParams();

  // Check if the channel name exists
  const { data, loading, error } = useFetchWithAuth(`/api/dashboard/channel/${channelName}`);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ChannelStatsComponent data={data} loading={loading} error={error} />
  );
}

export default LifetimeChannelStatsPage;
