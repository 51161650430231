import React, { useState } from 'react';

function StreamerViewDataComponent() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const handleFile1Change = (e) => {
    setFile1(e.target.files[0]);
  };

  const handleFile2Change = (e) => {
    setFile2(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: Send the files to your API
    // You can use the FormData API to send the files as multipart/form-data

    // Example code to send the files using fetch:
    const formData = new FormData();
    formData.append('streamerData', file1);
    formData.append('viewerData', file2);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/app/upload-data`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        // Handle the API response
      })
      .catch(() => {
        // Handle any errors
      });
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <form onSubmit={handleSubmit} className="max-w-sm">
        <div className="mb-4">
          <label htmlFor="streamerData" className="block mb-2">
            streamerData:
            <input type="file" id="streamerData" onChange={handleFile1Change} className="border border-gray-300 p-2" accept=".csv" />
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="viewerData" className="block mb-2">
            viewerData:
            <input type="file" id="viewerData" onChange={handleFile2Change} className="border border-gray-300 p-2" accept=".csv" />
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Upload</button>
      </form>
    </div>
  );
}

export default StreamerViewDataComponent;
