import React, { useEffect } from 'react';
import { Bar, Pie, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement,
} from 'chart.js';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

function LifetimeStats() {
  const { data: stats, loading, error } = useFetchWithAuth('/api/dashboard/lifetime-stats');

  useEffect(() => {
    document.title = 'MyStats - Dashboard - Lifetime'; // Set the page title here
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error.message}
      </div>
    );
  }

  if (!stats || stats.length === 0) {
    return <div>No stats available</div>;
  }

  const raceWidgets = stats.widgets.filter((stat) => stat.raceType === 'race');
  const brWidgets = stats.widgets.filter((stat) => stat.raceType === 'royale');

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Lifetime Stats</h1>
      <div className="grid grid-cols-4 gap-4 mb-8">
        {raceWidgets.map((stat) => (
          <div key={stat.label} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{stat.label}</h2>
            <p className="text-3xl font-semibold">{stat.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-5 gap-4 mb-8">
        {brWidgets.map((stat) => (
          <div key={stat.label} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{stat.label}</h2>
            <p className="text-3xl font-semibold">{stat.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-4 gap-4 mb-8">
        {stats.graphData.map((graph) => (
          <div key={graph.title} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{graph.title}</h2>
            {(() => {
              switch (graph.graphType.toLowerCase()) {
                case 'bar':
                  return <Bar data={{ labels: graph.labels, datasets: graph.datasets }} />;
                case 'line':
                  return <Line data={{ labels: graph.labels, datasets: graph.datasets }} />;
                case 'pie':
                  return <Pie data={{ labels: graph.labels, datasets: graph.datasets }} />;
                default:
                  return <div>Unknown graph type</div>;
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LifetimeStats;
