import React, { useState } from 'react';
import useFetch from '../../../hooks/useFetch'; // Import the useFetch hook

function EventDataComponent() {
  const [eventId, setEventId] = useState(null);
  const [file2, setFile2] = useState(null);
  const { fetchData, loading, error } = useFetch(); // Use the useFetch hook
  const [successMessage, setSuccessMessage] = useState(); // State for success message

  const handleEventIdChange = (e) => {
    setEventId(e.target.value);
  };

  const handleFile2Change = (e) => {
    setFile2(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('eventId', eventId);
    formData.append('eventData', file2);

    // Use fetchData from useFetch hook
    const response = await fetchData('/api/app/upload-event-data', {
      method: 'POST',
      body: formData,
    });

    if (response.error) {
      setSuccessMessage(
        {
          color: 'red',
          message: response.error,
        },
      ); // Set success message
      setTimeout(() => setSuccessMessage(''), 5000); // Clear message after 5 seconds
    } else {
      setSuccessMessage(
        {
          color: 'green',
          message: 'Upload successful!',
        },
      ); // Set success message
      setTimeout(() => setSuccessMessage(''), 5000); // Clear message after 5 seconds
    }
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <form onSubmit={handleSubmit} className="max-w-sm">
        <div className="mb-4">
          <label htmlFor="eventId" className="block mb-2">
            eventId:
            <input type="text" id="eventId" onChange={handleEventIdChange} className="border border-gray-300 p-2" />
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="eventData" className="block mb-2">
            eventData:
            <input type="file" id="eventData" onChange={handleFile2Change} className="border border-gray-300 p-2" />
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </button>
        {error && <p className="text-red-500">{error.message}</p>} {/* Display error message */}
        {successMessage && <p className={`text-${successMessage.color}-500`}>{successMessage.message}</p>} {/* Display success message */}
      </form>
    </div>
  );
}

export default EventDataComponent;
