import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import useFetch from '../../hooks/useFetch'; // Adjust the import path as needed

function CreatePatchNotes() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const patchNoteId = queryParams.get('id');
  const mdParser = new MarkdownIt();

  const { fetchData, loading, error } = useFetch();

  useEffect(() => {
    if (patchNoteId) {
      fetchData(`/api/patch-notes/${patchNoteId}`)
        .then((data) => {
          if (data) {
            setTitle(data.title);
            setContent(data.content);
          }
        })
        .catch((err) => console.error(err));
    }
  }, [patchNoteId, fetchData]);

  const handleEditorChange = ({ text }) => {
    setContent(text);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const patchNote = { title, content };

    const url = patchNoteId
      ? `/api/patch-notes/${patchNoteId}`
      : '/api/patch-notes';

    const method = patchNoteId ? 'PUT' : 'POST';

    fetchData(url, {
      method,
      body: patchNote,
    })
      .then(() => {
        navigate('/download');
      })
      .catch((err) => console.error(err));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="min-h-screen p-8">
      <div className="max-w-3xl mx-auto bg-gray-100 shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-center">{patchNoteId ? 'Edit Patch Note' : 'Create Patch Note'}</h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="title">
              Title
              <input
                id="title"
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </label>
          </div>
          <div className="mb-4">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="content-editor" className="block text-gray-700 text-sm font-bold mb-2">
              Content
            </label>
            <MdEditor
              style={{ height: '500px' }}
              renderHTML={(text) => mdParser.render(text)}
              onChange={handleEditorChange}
              value={content}
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
            >
              {patchNoteId ? 'Update Patch Note' : 'Create Patch Note'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreatePatchNotes;
