import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';
import ChannelStatsComponent from './ChannelStatsComponent';

function SeasonChannelStatsPage() {
  const { season, channelName } = useParams();

  const { data, loading, error } = useFetchWithAuth(`/api/dashboard/season/${season}/${channelName}`);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error.error}
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <div>No Data Available</div>;
  }

  return (
    <div>
      <ChannelStatsComponent data={data} loading={loading} error={error} season={season} />
    </div>
  );
}

export default SeasonChannelStatsPage;
