import React from 'react';
import StreamerViewDataComponent from './CSVUploadTestingComponents/StreamerViewDataComponent';
import MPLDataComponent from './CSVUploadTestingComponents/MPLDataComponent';
import EventDataComponent from './CSVUploadTestingComponents/EventDataComponent';

function CSVUploadTesting() {
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">CSV Upload Testing</h1>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <StreamerViewDataComponent />
        </div>
        <div>
          <MPLDataComponent />
        </div>
        <div>
          <EventDataComponent />
        </div>
      </div>
    </div>
  );
}

export default CSVUploadTesting;
