import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useFetchWithAuth = (endpoint) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchData = useCallback(async (method, body = null, url = endpoint) => {
    setLoading(true);
    setError(null);
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      navigate('/login');
      return null;
    }

    try {
      const options = {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      if (body) {
        options.body = JSON.stringify(body);
      }

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, options);

      if (response.status === 401) {
        navigate('/login');
        return null;
      }

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
      return result;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [endpoint, navigate]);

  useEffect(() => {
    fetchData('GET');
  }, [fetchData]);

  const post = (body, url) => fetchData('POST', body, url);
  const put = (body, url) => fetchData('PUT', body, url);
  const del = (url) => fetchData('DELETE', null, url);

  return {
    data, loading, error, post, put, del,
  };
};

export default useFetchWithAuth;
