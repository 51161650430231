import React, { useState, useEffect } from 'react';
import useFetchWithAuth from '../../hooks/useFetchWithAuth'; // Assuming this is your custom hook

function MyStatsSettingsPage() {
  const {
    data: settings, loading, error, put,
  } = useFetchWithAuth('/api/admin/getSettings');
  const [formData, setFormData] = useState(null);
  const [success, setSuccess] = useState(false); // State for success message
  const [updateError, setUpdateError] = useState(null); // State for update error message

  useEffect(() => {
    if (settings) {
      setFormData(settings);
    }
  }, [settings]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccess(false); // Reset success message
    setUpdateError(null); // Reset error message

    try {
      const data = await put(formData, '/api/admin/updateSettings');
      console.log('Settings updated successfully:', data);
      setSuccess(true); // Update success state
    } catch (formError) {
      setUpdateError('Error updating settings'); // Update error state
    }
  };

  const labelMapping = {
    season: 'Season',
    version: 'Version',
    dev_season: 'Development Season',
    dev_version: 'Development Version',
    // Add more mappings as needed
  };

  if (loading) return <div>Loading...</div>;
  if (error) {
    return (
      <div>
        Error:
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Settings</h1>
      {success && ( // Display success message
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Success!</strong>
          <span className="block sm:inline"> Settings updated successfully.</span>
        </div>
      )}
      {updateError && ( // Display error message
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline">
            {' '}
            {updateError}
          </span>
        </div>
      )}
      <form onSubmit={handleSubmit} className="space-y-4">
        {formData && Object.keys(formData).map((key) => (
          <div key={key} className="flex flex-col">
            <label htmlFor={key} className="mb-2 font-semibold">
              {labelMapping[key] || key}
              <input
                id={key}
                type="text"
                name={key}
                value={formData[key]}
                onChange={handleChange}
                className="p-2 border border-gray-300 rounded"
              />
            </label>
          </div>
        ))}
        <button type="submit" className="bg-blue-500 text-white p-2 rounded">Save</button>
      </form>
    </div>
  );
}

export default MyStatsSettingsPage;
