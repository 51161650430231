import React from 'react';
import {
  Link, useParams, useNavigate,
} from 'react-router-dom';
import { Bar, Line, Pie } from 'react-chartjs-2';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';
import NotFoundPage from '../server_responses/404NotFoundPage';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';

function ChannelStatsComponent({ data, loading, error }) {
  const { season } = useParams();
  const navigate = useNavigate();

  const { data: seasonsData, loading: seasonLoading, error: seasonError } = useFetchWithAuth(`/api/dropdowns/user-seasons-channel/${data.channel.name}`);

  if (loading || seasonLoading) {
    // Show loading spinner or skeleton screen
    return <div>Loading...</div>;
  }

  if (error || seasonError) {
    // Show error message
    return error ? (
      <div>
        Error:
        {error.error}
      </div>
    ) : (
      <div>
        Error:
        {seasonError.error}
      </div>
    );
  }

  const channelExists = data !== null;

  if (!channelExists) {
    // Redirect the user back to /dashboard/channel
    return <NotFoundPage />;
  }

  const raceWidgets = data.widgets.filter((stat) => stat.raceType === 'race');
  const brWidgets = data.widgets.filter((stat) => stat.raceType === 'royale');

  const handleSeasonChange = (event) => {
    const selectedSeason = event.target.value;
    if (selectedSeason === '') {
      navigate(`/dashboard/channel/${data.channel.name}`);
    } else {
      navigate(`/dashboard/channel/${data.channel.name}/${selectedSeason}`);
    }
  };

  return (
    <div>
      <div className="relative flex justify-center items-center mb-4">
        {
                    (season && season > 0)
                      ? (
                        <Link to={`/dashboard/season/${season}`} className="absolute left-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                          Back to S
                          {season}
                          {' '}
                          Channel List
                        </Link>
                      )
                      : (
                        <Link to="/dashboard/channel" className="absolute left-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Back to Channel List</Link>
                      )
                }
        <img
          src={data.channel.profileImageUrl || '/assets/images/user_circle.webp'}
          alt="Profile"
          className="profile-pic w-12 h-12 rounded-full mr-4"
        />
        <h1 className="text-2xl font-bold">
          {data.channel.name}
        </h1>
        <select
          className="absolute right-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
          defaultValue={season || ''}
          onChange={handleSeasonChange}
        >
          <option value="">Lifetime</option>
          {seasonsData.seasonsWithLabel.map((seasonoption) => (
            <option
              key={seasonoption.value}
              value={seasonoption.value}
            >
              {seasonoption.label}
            </option>
          ))}
        </select>
      </div>
      <div className="grid grid-cols-4 gap-4 mb-8">
        {raceWidgets.map((stat) => (
          <div key={stat.label} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{stat.label}</h2>
            <p className="text-3xl font-semibold">{stat.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-5 gap-4 mb-8">
        {brWidgets.map((stat) => (
          <div key={stat.label} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{stat.label}</h2>
            <p className="text-3xl font-semibold">{stat.value}</p>
          </div>
        ))}
      </div>
      <div className="grid grid-cols-4 gap-4 mb-8">
        {data.graphData.map((graph) => (
          <div key={graph.title} className="p-4 border rounded bg-gray-100">
            <h2 className="text-xl font-semibold">{graph.title}</h2>
            {(() => {
              switch (graph.graphType.toLowerCase()) {
                case 'bar':
                  return <Bar data={{ labels: graph.labels, datasets: graph.datasets }} />;
                case 'line':
                  return <Line data={{ labels: graph.labels, datasets: graph.datasets }} />;
                case 'pie':
                  return <Pie data={{ labels: graph.labels, datasets: graph.datasets }} />;
                default:
                  return <div>Unknown graph type</div>;
              }
            })()}
          </div>
        ))}
      </div>
    </div>
  );
}

ChannelStatsComponent.propTypes = {
  data: PropTypes.shape({
    channel: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profileImageUrl: PropTypes.string,
    }).isRequired,
    widgets: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    graphData: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      graphType: PropTypes.string.isRequired,
      labels: PropTypes.arrayOf(PropTypes.string).isRequired,
      datasets: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        data: PropTypes.arrayOf(PropTypes.number).isRequired,
      })).isRequired,
    })).isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    error: PropTypes.string,
  }),
};

ChannelStatsComponent.defaultProps = {
  error: null,
};

export default ChannelStatsComponent;
