import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function LogoutPage() {
  const navigate = useNavigate();

  useEffect(() => {
    // Clear user session or token
    localStorage.removeItem('userToken'); // Adjust this based on your auth logic

    // Redirect to login page
    navigate('/login');
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/images/background.jpg')" }}>
      <h1 className="text-5xl text-white mb-4">Logging out...</h1>
    </div>
  );
}

export default LogoutPage;
