import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MultiSelect } from 'react-multi-select-component';
import useFetch from '../../hooks/useFetch';

function ManageEventPage() {
  const { id } = useParams();
  const { fetchData, loading, error } = useFetch();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [participants, setParticipants] = useState([]);
  const [users, setUsers] = useState([]);
  const [leaderboards, setLeaderboards] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');

  const formatDateForInput = useCallback((dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return Number.isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
  }, []);

  const isEditable = useMemo(() => {
    if (event) {
      return new Date() < new Date(event.startAt);
    }
    return false;
  }, [event]);

  useEffect(() => {
    const fetchEventAndUsersAndLeaderboards = async () => {
      const eventData = await fetchData(`/api/events/getById/${id}`);
      const usersData = await fetchData('/api/dropdowns/users');
      const leaderboardsData = await fetchData('/api/dropdowns/leaderboards');

      if (eventData.unauthorized) {
        navigate('/unauthorized');
        return;
      }

      if (eventData) {
        setEvent({
          ...eventData,
          startAt: formatDateForInput(eventData.startAt),
          endAt: formatDateForInput(eventData.endAt),
        });
        setParticipants((eventData.participantUsers && eventData.participantUsers.map(
          (p) => ({ label: p.username, value: p.id }),
        )) || []);
      }
      setUsers(usersData.usersWithLabel || []);
      setLeaderboards(leaderboardsData || []);
    };

    fetchEventAndUsersAndLeaderboards();
  }, [id, fetchData, formatDateForInput, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !event.name || !event.startAt || !event.endAt
      || participants.length === 0 || !event.leaderboard_config_id
    ) {
      setSuccessMessage('Please fill in all fields before submitting.');
      return;
    }
    const updatedEvent = {
      ...event,
      participants: participants.map((p) => p.value),
    };
    const response = await fetchData(`/api/events/update/${id}`, { method: 'PUT', body: updatedEvent });
    if (response && response.event) {
      setEvent({
        ...response.event,
        startAt: formatDateForInput(response.event.startAt),
        endAt: formatDateForInput(response.event.endAt),
      });
      setParticipants((response.event.participantUsers && response.event.participantUsers.map(
        (p) => ({ label: p.username, value: p.id }),
      )) || []);
      setSuccessMessage(response.message || 'Event updated successfully!');
    } else {
      setSuccessMessage('Error updating event. Please try again.');
    }
  };

  const handleParticipantsChange = (selected) => {
    setParticipants(selected);
  };

  const canSubmit = useMemo(
    () => event && event.name && event.startAt && event.endAt
      && participants.length > 0 && event.leaderboard_config_id,
    [event, participants],
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.error}</div>;
  if (!event || !users || !leaderboards) return <div>Loading...</div>;

  return (
    <div className="container mx-auto p-4">
      {event && (
        <div className="flex justify-start items-center mb-4">
          <Link to={event.eventSeries ? `/my-events/manage-series/${event.eventSeries.id}` : '/my-events'} className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded border">Back</Link>
          <div className="flex-1 text-center">
            <h1 className="text-2xl font-bold text-white">Manage Event</h1>
          </div>
        </div>
      )}
      {successMessage && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-4 transition-opacity duration-300">
          {successMessage}
        </div>
      )}
      <div className="bg-gray-600 text-white border p-4 rounded-md">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block mb-2">
              Event Name:
              <input
                type="text"
                id="name"
                name="name"
                value={event.name}
                onChange={(e) => setEvent({ ...event, [e.target.name]: e.target.value })}
                disabled={!isEditable}
                className={`w-full p-2 border rounded ${isEditable ? 'text-black' : 'cursor-not-allowed'}`}
              />
            </label>
          </div>
          <div>
            <label htmlFor="startAt" className="block mb-2">
              Start Date:
              <input
                type="date"
                id="startAt"
                name="startAt"
                value={event.startAt}
                onChange={(e) => setEvent({ ...event, [e.target.name]: e.target.value })}
                disabled={!isEditable}
                className={`w-full p-2 border rounded ${isEditable ? 'text-black' : 'cursor-not-allowed'}`}
              />
            </label>
          </div>
          <div>
            <label htmlFor="endAt" className="block mb-2">
              End Date:
              <input
                type="date"
                id="endAt"
                name="endAt"
                value={event.endAt}
                onChange={(e) => setEvent({ ...event, [e.target.name]: e.target.value })}
                className="w-full p-2 border rounded text-black"
              />
            </label>
          </div>
          <div>
            <label htmlFor="leaderboard" className="block mb-2">
              Leaderboard Config:
              <select
                id="leaderboard"
                value={event.leaderboard_config_id}
                onChange={(e) => setEvent({ ...event, leaderboard_config_id: e.target.value })}
                className="w-full p-2 border rounded text-black"
              >
                <option value="">Please select</option>
                {leaderboards.map((leaderboard) => (
                  <option key={leaderboard.value} value={leaderboard.value}>
                    {leaderboard.label}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="participants" className="block mb-2">
              Participants:
              <MultiSelect
                options={users || []}
                value={participants}
                onChange={handleParticipantsChange}
                labelledBy="Select Participants"
                className="text-black"
              />
            </label>
          </div>
          {canSubmit && (
            <button type="submit" className="bg-blue-500 text-white p-2 rounded">Update Event</button>
          )}
        </form>
      </div>
    </div>
  );
}

export default ManageEventPage;
