import React, { createContext, useContext, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const UserContext = createContext();

export const useUserData = () => useContext(UserContext);

export function UserDataProvider({ userData, children, refreshUserData }) {
  const value = useMemo(() => ({ userData, refreshUserData }), [userData, refreshUserData]);

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  );
}

UserDataProvider.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.number.isRequired,
    // name: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  refreshUserData: PropTypes.func.isRequired,
};
