import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useUserData } from '../providers/UserDataProvider';
import useFetch from '../hooks/useFetch';

function DownloadPage() {
  const { userData, isLoading: userDataLoading } = useUserData();
  const [latestFile, setLatestFile] = useState(null);
  const [patchNotes, setPatchNotes] = useState([]);
  const [visibleNotes, setVisibleNotes] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [downloadFileError, setDownloadFileError] = useState(null);
  const [showEditDeleteButtons, setShowEditDeleteButtons] = useState(false);
  const mdParser = new MarkdownIt();
  const [isDownloading, setIsDownloading] = useState(false); // State for download status

  const { fetchData, loading, error } = useFetch();

  useEffect(() => {
    document.title = 'MyStats - Download';
    if (userData) {
      setShowEditDeleteButtons(userData.isAdmin);
    }
  }, [userData]);

  const handleDownload = async () => {
    setIsDownloading(true); // Set downloading state to true
    try {
      if (!latestFile || !latestFile.fileName) {
        throw new Error('Latest file information is not available');
      }

      const response = await fetchData(`/download/${latestFile.fileName}`, { method: 'GET', isBlob: true });

      // Check if the response is a Blob
      if (response instanceof Blob) {
        const url = window.URL.createObjectURL(response);
        const a = document.createElement('a');
        a.href = url;
        a.download = latestFile.fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        // If response is not a Blob, handle it as an error
        const errorData = await response.json(); // Attempt to parse as JSON
        throw new Error(errorData.error || 'Invalid response format');
      }
    } catch (err) {
      console.error('Error downloading file:', err);
      setDownloadFileError(err.message); // Set the error message to display
    } finally {
      setIsDownloading(false); // Reset downloading state
    }
  };

  useEffect(() => {
    const fetchLatestFile = async () => {
      const data = await fetchData('/api/latest-version');
      if (data.error) {
        setDownloadFileError(data.error);
      }
      if (data) {
        setLatestFile(data);
      }
    };

    const fetchPatchNotes = async () => {
      const data = await fetchData('/api/patch-notes');
      if (data) {
        setPatchNotes(data);
        if (data.length > 0) {
          setVisibleNotes({ [data[0].id]: true });
        }
      }
    };

    fetchLatestFile();
    fetchPatchNotes();
  }, [fetchData]);

  const toggleVisibility = (id) => {
    setVisibleNotes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const confirmDelete = (id) => {
    setNoteToDelete(id);
    setIsModalOpen(true);
  };

  const deleteNote = async () => {
    const response = await fetchData(`/api/patch-notes/${noteToDelete}`, { method: 'DELETE' });
    if (response) {
      setPatchNotes((prevNotes) => {
        const updatedNotes = prevNotes.filter((note) => note.id !== noteToDelete);

        // Check if the deleted note was the most recent and if there are no other open items
        const wasDeletedMostRecent = prevNotes[0].id === noteToDelete;
        const noOtherOpenItems = Object.values(visibleNotes).filter(Boolean).length <= 1;

        if (wasDeletedMostRecent && noOtherOpenItems) {
          // Find the next latest note
          const nextLatestNote = updatedNotes[0];

          // Update visibleNotes to open the next latest note
          if (nextLatestNote) {
            setVisibleNotes({ [nextLatestNote.id]: true });
          } else {
            setVisibleNotes({});
          }
        } else {
          // Remove the deleted note from visibleNotes
          setVisibleNotes((prev) => {
            const { [noteToDelete]: _, ...rest } = prev;
            return rest;
          });
        }

        return updatedNotes;
      });
      setIsModalOpen(false);
      setNoteToDelete(null);
    }
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
    setNoteToDelete(null);
  };

  const shouldShowEditDeleteButtons = () => showEditDeleteButtons;

  if (loading || userDataLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="">
      <div className="max-w-3xl mx-auto bg-gray-600 border shadow-md rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-center text-white">Download Latest Version</h1>
        <div className="text-center mb-6">
          {latestFile && !downloadFileError && (
            <button
              type="button"
              onClick={handleDownload}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300"
              disabled={isDownloading} // Disable button while downloading
            >
              {isDownloading ? 'Downloading...' : `Download ${latestFile.fileName}`}
            </button>
          )}
          {downloadFileError && <button type="button" className="text-white bg-red-500 py-2 px-4 rounded hover:bg-red-700 transition duration-300">{downloadFileError}</button>}
        </div>
      </div>
      <div className="max-w-3xl mx-auto bg-gray-600 border shadow-md rounded-lg p-6 mt-8">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-white">Patch Notes</h2>
          {shouldShowEditDeleteButtons() && (
            <Link
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition duration-300 flex items-center"
              to="/admin/create-patch-notes"
            >
              <span className="mr-2">Create New Post</span>
              <svg
                className="w-4 h-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </Link>
          )}
        </div>
        <ul className="list-inside">
          {patchNotes.length > 0 ? (
            patchNotes.map((note) => (
              <li key={note.id} className="bg-white p-4 mb-4 rounded shadow">
                <div className="flex justify-between items-center pb-2">
                  <button
                    type="button"
                    className="text-xl font-semibold cursor-pointer text-left w-full"
                    onClick={() => toggleVisibility(note.id)}
                  >
                    {note.title}
                  </button>
                  {shouldShowEditDeleteButtons() && (
                    <div>
                      <Link to={`/admin/create-patch-notes?id=${note.id}`} className="text-blue-500 hover:underline mr-2">Edit</Link>
                      <button type="button" className="text-red-500 hover:underline" onClick={() => confirmDelete(note.id)}>Delete</button>
                    </div>
                  )}
                </div>
                {visibleNotes[note.id] && (
                  <MdEditor
                    value={note.content}
                    renderHTML={(text) => mdParser.render(text)}
                    readOnly
                    view={{ menu: false, md: false, html: true }}
                  />
                )}
              </li>
            ))
          ) : (
            <li className="text-white">No patch notes available.</li>
          )}
        </ul>
      </div>
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <h2 className="text-xl font-bold mb-4">Confirm Deletion</h2>
            <p>Are you sure you want to delete this patch note?</p>
            <div className="mt-4 flex justify-end">
              <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded mr-2" onClick={cancelDelete}>Cancel</button>
              <button type="button" className="bg-red-500 text-white px-4 py-2 rounded" onClick={deleteNote}>Delete</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DownloadPage;
