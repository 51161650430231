/* eslint-disable object-curly-newline */
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useFetch from '../../hooks/useFetch';
import './EventLeaderboard.css';
import { useUserData } from '../../providers/UserDataProvider';

function PodiumModal({ top3, onClose }) {
  const getOrdinalSuffix = (place) => {
    if (place === 1) return 'st';
    if (place === 2) return 'nd';
    if (place === 3) return 'rd';
    return 'th';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-16 rounded-lg shadow-xl max-w-5xl w-11/12">
        <h2 className="text-6xl font-bold mb-12 text-center">Top 3 Racers</h2>
        <div className="flex justify-between items-end mb-16">
          {[
            { place: 2, color: 'text-blue-400', size: 'text-8xl', nameSize: 'text-3xl', emote: '🥈' },
            { place: 1, color: 'text-yellow-400', size: 'text-9xl', nameSize: 'text-4xl', emote: '🥇' },
            { place: 3, color: 'text-green-400', size: 'text-7xl', nameSize: 'text-2xl', emote: '🥉' },
          ].map(({ place, color, size, nameSize, emote }) => (
            <div key={place} className="text-center flex flex-col items-center">
              <span className={`${nameSize} font-bold mb-8 max-w-[250px] break-words animate-jump-${place}`}>
                {top3[place - 1].racer_name}
              </span>
              <span className={`${color} ${size} mb-6 animate-jiggle`}>
                {emote}
              </span>
              <span className="text-3xl font-bold">
                {place}{getOrdinalSuffix(place)}
              </span>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-6 px-10 rounded w-full text-3xl"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

PodiumModal.propTypes = {
  top3: PropTypes.arrayOf(PropTypes.shape({
    racer_name: PropTypes.string.isRequired,
  })).isRequired,
  onClose: PropTypes.func.isRequired,
};

function EventLeaderboardPage() {
  const { eventId } = useParams();
  const { fetchData, loading, error } = useFetch();
  const [leaderboardsData, setLeaderboardsData] = useState([]);
  const [selectedStreamer, setSelectedStreamer] = useState('all');
  const [showDetails, setShowDetails] = useState(false);
  const [revealedRank, setRevealedRank] = useState(null);
  const [showPodium, setShowPodium] = useState(false);
  const navigate = useNavigate();
  const { userData } = useUserData();

  const getRevealDuration = (rank) => {
    if (rank > 10) return 1000;
    if (rank > 5) return 1000 + (11 - rank) * 100;
    return 2000 + (6 - rank) * 500;
  };

  const maskName = (name, isRevealed) => {
    if (isRevealed) return name;
    return '•'.repeat(20);
  };

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const data = await fetchData(`/api/events/get-leaderboard/${eventId}`);
        if (data.unauthorized) {
          navigate('/unauthorized', { state: { from: `/api/events/get-leaderboard/${eventId}` } });
        } else {
          setLeaderboardsData(data);
          if (data.some((entry) => entry.userId === userData.id)) {
            setSelectedStreamer(userData.id);
          }
        }
      } catch (err) {
        console.error('Error fetching leaderboard:', err);
      }
    };

    fetchLeaderboard();
  }, [fetchData, eventId, navigate, userData.id]);

  const handleStreamerChange = (event) => {
    const { value } = event.target;
    setSelectedStreamer(value === 'all' ? 'all' : Number(value));
    setRevealedRank(null);
    setShowPodium(false);
  };

  const handleReveal = () => {
    if (revealedRank === null) {
      setRevealedRank(11); // Reveal all ranks above 10
    } else if (revealedRank > 1) {
      setRevealedRank(revealedRank - 1); // Reveal next rank
    } else if (revealedRank === 1) {
      setShowPodium(true);
    }
  };

  const getRevealButtonText = () => {
    if (revealedRank === null) return 'Start Reveal';
    if (revealedRank === 1) return 'Show Podium';
    return `Reveal Rank ${revealedRank - 1}`;
  };

  const getCombinedLeaderboard = () => {
    const allEntries = leaderboardsData.flatMap((data) => data.leaderboard);
    const combinedEntries = allEntries.reduce((acc, entry) => {
      const existingEntry = acc.find((e) => e.racer_name === entry.racer_name);
      if (existingEntry) {
        Object.keys(entry).forEach((key) => {
          if (typeof entry[key] === 'number') {
            existingEntry[key] += entry[key];
          }
        });
      } else {
        acc.push({ ...entry });
      }
      return acc;
    }, []);

    return combinedEntries.sort((a, b) => b.event_points - a.event_points)
      .map((entry, index) => ({ ...entry, rank: index + 1 }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const currentLeaderboard = selectedStreamer === 'all'
    ? getCombinedLeaderboard()
    : leaderboardsData.find((data) => data.userId === selectedStreamer)?.leaderboard || [];
  const top3 = currentLeaderboard.slice(0, 3);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Event Leaderboard</h1>

      <div className="space-x-2 flex justify-between">
        <div>
          <button
            type="button"
            className="bg-blue-500 hover:bg-blue-700 border border-blue-600 shadow-md text-white font-bold py-3 px-4 rounded mr-2"
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? 'Hide Details' : 'Show Details'}
          </button>
          <button
            type="button"
            className="bg-green-500 hover:bg-green-700 border border-green-600 shadow-md text-white font-bold py-3 px-4 rounded"
            onClick={handleReveal}
          >
            {getRevealButtonText()}
          </button>
        </div>
        <div className="mb-4 border bg-gray-600 rounded-md w-fit p-2">
          <label htmlFor="streamer-select" className="">
            <span className="text-white">Select Streamer:</span>
            <select
              id="streamer-select"
              value={selectedStreamer}
              onChange={handleStreamerChange}
              className="border rounded px-2 py-1 ml-2"
            >
              <option value="all">All Streamers</option>
              {leaderboardsData.map((data) => (
                <option key={data.userId} value={data.userId}>{data.username}</option>
              ))}
            </select>
          </label>
        </div>
      </div>

      <div className="overflow-x-auto bg-gray-600 border border-1 rounded-md">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Position</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Racer Name</th>
              <th className="border border-gray-300 bg-gray-300 px-4 py-2">Event Points</th>
              {showDetails && (
                <>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Race Points</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Race Wins</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Race Races</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Race HS</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">BR Points</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">BR Wins</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">BR Races</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">BR HS</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Kills</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Eliminations</th>
                  <th className="border border-gray-300 bg-gray-300 px-4 py-2">Crown Wins</th>
                </>
              )}
            </tr>
          </thead>
          <tbody className="text-white">
            {currentLeaderboard.length > 0 ? (
              currentLeaderboard.map((entry) => {
                const isRevealed = revealedRank === null ? false : entry.rank >= revealedRank;
                return (
                  <tr key={entry.id}>
                    <td className="border border-gray-300 px-4 py-2">{entry.rank}</td>
                    <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>
                      <span className="font-mono">{maskName(entry.racer_name, isRevealed)}</span>
                    </td>
                    <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.event_points}</td>
                    {showDetails && (
                      <>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.race_points}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.race_wins}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.race_races}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.race_hs}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.br_points}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.br_wins}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.br_races}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.br_hs}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.kills}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.eliminations}</td>
                        <td className={`border border-gray-300 px-4 py-2 transition-all ease-in-out ${isRevealed ? '' : 'blur-sm'}`} style={{ transitionDuration: `${getRevealDuration(entry.rank)}ms` }}>{entry.crown_wins}</td>
                      </>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={showDetails ? 14 : 3} className="text-center text-white">No event data for this streamer yet.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showPodium && <PodiumModal top3={top3} onClose={() => setShowPodium(false)} />}
    </div>
  );
}

export default EventLeaderboardPage;
