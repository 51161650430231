import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './i18n';
import LoginPage from './components/LoginPage';
import ExternalLayout from './layouts/ExternalLayout';
import InternalLayout from './layouts/InternalLayout';
import LogoutPage from './components/LogoutPage';
import DashboardPage from './components/dashboard/DashboardPage';
import LeaderboardsPage from './components/LeaderboardsPage';
import NotFoundPage from './components/server_responses/404NotFoundPage';
import OAuthCallback from './components/OAuthCallBack';
import AdminUpload from './components/admin/AdminUpload';
import DownloadPage from './components/DownloadPage';
import CreatePatchNotes from './components/admin/CreatePatchNote';
import LifetimeStatsPage from './components/dashboard/LifetimeStatsPage';
import ChannelStatsListPage from './components/dashboard/LifetimeChannelListPage';
import SeasonStatsPage from './components/dashboard/SeasonStatsPage';
import SeasonChannelListPage from './components/dashboard/SeasonChannelListPage';
import LifetimeChannelStatsPage from './components/dashboard/LifetimeChannelStatsPage';
import SeasonChannelStatsPage from './components/dashboard/SeasonChannelStatsPage';
import EventPage from './components/events/EventPage';
import ManageEventPage from './components/events/ManageEventPage';
import CreateEventPage from './components/events/CreateEventPage';
import MyStatsSettingsPage from './components/admin/MyStatsSettingsPage';
import CSVUploadTesting from './components/admin/CSVUploadTesting';
import LeaderboardSetupPage from './components/events/LeaderboardSetupPage';
import EventLeaderboardPage from './components/events/EventLeaderboard';
import ManageEventSeries from './components/events/ManageEventSeries';
import UnauthorizedPage from './components/server_responses/401UnauthorizedPage';

function App() {
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging') {
      setMessage('You are in development mode');
    }
  }, []);

  return (
    <Router>
      {message && (
        <div style={{
          position: 'fixed',
          bottom: '8px',
          left: '12px',
          backgroundColor: 'yellow',
          padding: '5px 10px',
          zIndex: 1000,
          borderRadius: '5px',
          boxShadow: '0 0 10px rgba(0,0,0,0.1)',
        }}
        >
          {message}
        </div>
      )}
      {' '}
      <Routes>
        <Route path="" element={<ExternalLayout><LoginPage /></ExternalLayout>} />
        <Route path="/login" element={<ExternalLayout><LoginPage /></ExternalLayout>} />
        <Route path="/logout" element={<ExternalLayout><LogoutPage /></ExternalLayout>} />
        <Route path="/auth/callback" element={<OAuthCallback />} />
        <Route path="/" element={<InternalLayout />}>
          {/* Dashboard Routes */}
          <Route path="/dashboard" element={<DashboardPage />}>
            <Route path="/dashboard/lifetime" element={<LifetimeStatsPage />} />
            <Route path="/dashboard/channel" element={<ChannelStatsListPage />} />
            <Route path="/dashboard/channel/:channelName" element={<LifetimeChannelStatsPage />} />
            <Route path="/dashboard/channel/:channelName/:season" element={<SeasonChannelStatsPage />} />
            <Route path="/dashboard/season" element={<SeasonStatsPage />} />
            <Route path="/dashboard/season/:season" element={<SeasonChannelListPage />} />
          </Route>
          <Route path="/my-events" element={<EventPage />} />
          <Route path="/my-events/view-leaderboard/:eventId" element={<EventLeaderboardPage />} />
          <Route path="/my-events/create" element={<CreateEventPage />} />
          <Route path="/my-events/manage/:id" element={<ManageEventPage />} />
          <Route path="/my-events/manage-series/:id" element={<ManageEventSeries />} />
          <Route path="/new-leaderboard-config" element={<LeaderboardSetupPage />} />
          <Route path="/manage-leaderboard-config/:id" element={<LeaderboardSetupPage />} />
          <Route path="/download" element={<DownloadPage />} />
          <Route path="/leaderboards" element={<LeaderboardsPage />} />
          {' '}
          {/* Add this line */}
          {/* Admin routes */}
          <Route path="/admin/upload-new-version" element={<AdminUpload />} />
          <Route path="/admin/create-patch-notes" element={<CreatePatchNotes />} />
          <Route path="/admin/update-mystats-settings" element={<MyStatsSettingsPage />} />
          <Route path="/admin/csv-testing" element={<CSVUploadTesting />} />
          {/* Unathorized routes */}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          {/* All other routes should return not found */}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
