import React from 'react';
import { useParams } from 'react-router-dom';
import useFetchWithAuth from '../../hooks/useFetchWithAuth';
import ChannelList from './ChannelListComponent';

function SeasonChannelListPage() {
  const { season } = useParams();

  const { data, loading, error } = useFetchWithAuth(`/api/dashboard/get-user-season-channels/${season}`);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div>
        Error:
        {error.error}
      </div>
    );
  }

  if (!data || data.length === 0) {
    return <div>No Data Available</div>;
  }

  return (
    <div>
      <ChannelList channels={data} loading={loading} error={error} season={season} />
    </div>
  );
}

export default SeasonChannelListPage;
