import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function OAuthCallBack() {
  const navigate = useNavigate();

  useEffect(() => {
    const { hash } = window.location;
    const token = new URLSearchParams(hash.substring(1)).get('access_token');
    console.log('token:', token);
    if (token) {
      fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/twitch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.token) {
            localStorage.setItem('jwtToken', data.token);
            navigate('/dashboard');
          } else {
            console.error('User creation failed');
            navigate('/login');
          }
        })
        .catch(() => {
          navigate('/login');
        });
    } else {
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/assets/images/background.jpg')" }} />
  );
}

export default OAuthCallBack;
