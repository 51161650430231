import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

function ChannelListComponent({
  channels, loading, error, season,
}) {
  const [sortCriteria, setSortCriteria] = useState('channel');

  if (loading) return <div>Loading...</div>;
  if (error) {
    return (
      <div>
        Error:
        {error.error}
      </div>
    );
  }

  const handleSort = (criteria) => {
    setSortCriteria(criteria);
  };

  const sortedChannels = [...channels].sort((a, b) => {
    switch (sortCriteria) {
      case 'most_points':
        return (Number(b.race_points) + Number(b.br_points))
          - (Number(a.race_points) + Number(a.br_points));
      case 'most_races':
        return (Number(b.race_races) + Number(b.br_races))
          - (Number(a.race_races) + Number(a.br_races));
      case 'most_wins':
        return (Number(b.race_wins) + Number(b.br_wins))
          - (Number(a.race_wins) + Number(a.br_wins));
      case 'channel':
      default:
        return a.channel.localeCompare(b.channel);
    }
  });

  const getSortedValue = (channel) => {
    const formatter = new Intl.NumberFormat('en-US');

    switch (sortCriteria) {
      case 'most_points':
        return `${formatter.format(Number(channel.race_points) + Number(channel.br_points))} points`;
      case 'most_races':
        return `${formatter.format(Number(channel.race_races) + Number(channel.br_races))} races`;
      case 'most_wins':
        return `${formatter.format(Number(channel.race_wins) + Number(channel.br_wins))} wins`;
      case 'channel':
        return '';
      default:
        return '';
    }
  };

  const getButtonClass = (criteria) => (sortCriteria === criteria
    ? 'btn bg-blue-700 text-white font-bold py-2 px-4 rounded'
    : 'btn bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded');

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <div className="relative mb-4 flex space-x-2 justify-center">
        {(season && season > 0) ? (
          <Link to="/dashboard/season" className="absolute left-0 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">Back to Seasons</Link>
        ) : null}
        <button
          type="button"
          onClick={() => handleSort('channel')}
          className={getButtonClass('channel')}
        >
          Sort by Channel
        </button>
        <button
          type="button"
          onClick={() => handleSort('most_points')}
          className={getButtonClass('most_points')}
        >
          Sort by Most Points
        </button>
        <button
          type="button"
          onClick={() => handleSort('most_races')}
          className={getButtonClass('most_races')}
        >
          Sort by Most Races
        </button>
        <button
          type="button"
          onClick={() => handleSort('most_wins')}
          className={getButtonClass('most_wins')}
        >
          Sort by Most Wins
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {sortedChannels.map((channel) => (
          <div key={channel.id} className="channel border bg-gray-100 shadow-md rounded-lg p-4 flex items-center justify-between">
            <div className="flex items-center">
              <img
                src={channel.user.profile_image_url || '/assets/images/user_circle.webp'}
                alt={channel.user.username || channel.channel}
                className="profile-pic w-12 h-12 rounded-full mr-4"
              />
              {
                (season && season > 0) ? (
                  <Link to={`/dashboard/channel/${channel.user.username ? channel.user.username.toLowerCase() : channel.channel}/${season}`} className="text-lg font-medium">{channel.user.username || channel.channel}</Link>
                ) : (
                  <Link to={`/dashboard/channel/${channel.user.username ? channel.user.username.toLowerCase() : channel.channel}`} className="text-lg font-medium">{channel.user.username || channel.channel}</Link>
                )
              }
            </div>
            <p className="text-sm text-gray-500">{getSortedValue(channel)}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

ChannelListComponent.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.shape({
    channel: PropTypes.string.isRequired,
    username: PropTypes.string,
    profile_image_url: PropTypes.string,
    race_points: PropTypes.string,
    br_points: PropTypes.string,
    race_races: PropTypes.string,
    br_races: PropTypes.string,
    race_wins: PropTypes.string,
    br_wins: PropTypes.string,
  })).isRequired,
  loading: PropTypes.bool,
  error: PropTypes.shape({
    error: PropTypes.string,
  }),
  season: PropTypes.number,
};

ChannelListComponent.defaultProps = {
  loading: false,
  error: null,
  season: null,
};

export default ChannelListComponent;
