import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSave } from '@fortawesome/free-solid-svg-icons';
/* eslint-disable import/no-extraneous-dependencies */
import Modal from 'react-modal';
import useFetch from '../../hooks/useFetch';

function ManageEventSeries() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { fetchData, loading, error } = useFetch();
  const [eventSeries, setEventSeries] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [eventSeriesNameEdit, setEventSeriesNameEdit] = useState();

  useEffect(() => {
    const fetchEventSeries = async () => {
      const data = await fetchData(`/api/events/getBySeriesId/${id}`);
      if (data[0]) {
        setEventSeries(data[0]);
      }
    };

    fetchEventSeries();
  }, [id, fetchData]);

  const openModal = () => {
    setEventSeriesNameEdit(eventSeries.event_series_name);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleSetEventSeries = (updatedEventSeries) => {
    const savedEventSeries = {
      ...eventSeries,
      event_series_name: updatedEventSeries.name,
    };
    setEventSeries(savedEventSeries);
  };

  const handleSaveEventSeries = async () => {
    const data = await fetchData(`/api/events/update-event-series/${id}`, { method: 'POST', body: { event_series_name: eventSeriesNameEdit } });
    if (data) {
      handleSetEventSeries(data);
    }
    closeModal();
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '60%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '80%', // Adjust this value to change the width of the modal
      maxWidth: '600px', // Set a max-width if needed
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // This will darken the background
    },
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.error}</div>;
  if (!eventSeries) return <div>No event series found.</div>;

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-row justify-between mb-4">
        <h1 className="text-2xl font-bold mb-4 text-white">Manage Event Series: {eventSeries.event_series_name}</h1>
        <button
          type="button"
          className="bg-amber-500 hover:bg-amber-700 text-white font-bold py-1 px-3 rounded"
          onClick={openModal}
          title="Edit Event Series Details"
        >
          Edit Series <FontAwesomeIcon icon={faPencil} />
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Event Series"
        style={customStyles}
      >
        <form>
          <div className="mb-4">
            <label htmlFor="event_series_name" className="block text-gray-700 text-sm font-bold mb-2">Event Series Name
              <input
                value={eventSeriesNameEdit}
                type="text"
                id="event_series_name"
                name="event_series_name"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-light focus:outline-none focus:shadow-outline"
                onChange={(e) => setEventSeriesNameEdit(e.target.value)}
              />
            </label>
          </div>
        </form>
        <div className="flex justify-between">
          <button type="button" className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-1 px-3 rounded" onClick={closeModal}>Close</button>
          <button
            type="button"
            className="bg-amber-500 hover:bg-amber-700 text-white font-bold py-1 px-3 rounded"
            onClick={handleSaveEventSeries}
          >
            Save <FontAwesomeIcon icon={faSave} />
          </button>
        </div>
      </Modal>
      <div className="flex flex-row justify-between">
        <div className="w-2/3 bg-gray-600 border p-4 rounded-md shadow mr-1">
          <h2 className="text-lg font-semibold mb-4 text-white">Events</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {eventSeries?.events?.map((event) => (
              <div key={event.id} className="bg-gray-100 p-4 rounded-md shadow">
                <h2 className="text-lg font-semibold text-center">{event.name}</h2>
                <div className="flex flex-col md:flex-row justify-between">
                  <div className="flex flex-col flex-col md:flex-col">
                    <p className="text-sm text-gray-500 flex-col md:flex-col">Start Date: {new Date(event.startAt).toLocaleDateString()}</p>
                    <p className="text-sm text-gray-500 flex-col md:flex-col">End Date: {new Date(event.endAt).toLocaleDateString()}</p>
                  </div>
                  <div className="flex flex-col md:flex-row lg:flex-col text-left md:text-right">
                    <p className="text-sm text-gray-500 flex-col md:flex-row">Participants: {event.eventParticipants.length}</p>
                    <p className="text-sm text-gray-500 flex-col md:flex-row">Leaderboard Submissions: {event.eventData[0] ? event.eventData[0].leaderboardSubmissions : 0}</p>
                  </div>
                </div>
                <div className="flex flex-row justify-between mt-4">
                  <button
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                    onClick={() => navigate(`/my-events/manage/${event.id}`)}
                    title="Manage Event"
                  >
                    Manage
                  </button>
                  {event.eventData[0] && event.eventData[0].leaderboardSubmissions > 0 && (
                    <button
                      type="button"
                      className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-3 rounded"
                      onClick={() => navigate(`/my-events/view-leaderboard/${event.id}`)}
                      title="View Leaderboards"
                    >
                      View Leaderboards
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="w-1/3 bg-gray-600 border p-4 rounded-md shadow">
          <h2 className="text-lg font-semibold mb-4 text-white">Event Series Collections</h2>
        </div>
      </div>
    </div>
  );
}

export default ManageEventSeries;
