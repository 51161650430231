import React, { useState } from 'react';

function MPLDataComponent() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);

  const handleFile1Change = (e) => {
    setFile1(e.target.files[0]);
  };

  const handleFile2Change = (e) => {
    setFile2(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: Send the files to your API
    // You can use the FormData API to send the files as multipart/form-data

    // Example code to send the files using fetch:

    const formData = new FormData();
    formData.append('raceData', file1);
    formData.append('checkpointData', file2);
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/app/upload-mpl-data`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then(() => {
        // Handle the API response
      })
      .catch(() => {
        // Handle any errors
      });
  };

  return (
    <div className="container mx-auto p-4 bg-gray-100">
      <form onSubmit={handleSubmit} className="max-w-sm">
        <div className="mb-4">
          <label htmlFor="raceData" className="block mb-2">
            raceData:
            <input type="file" id="raceData" onChange={handleFile1Change} className="border border-gray-300 p-2" />
          </label>
        </div>
        <div className="mb-4">
          <label htmlFor="checkpointData" className="block mb-2">
            checkpointData:
            <input type="file" id="checkpointData" onChange={handleFile2Change} className="border border-gray-300 p-2" />
          </label>
        </div>
        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">Upload</button>
      </form>
    </div>
  );
}

export default MPLDataComponent;
