import React, { useState, useRef } from 'react';
import useFetch from '../../hooks/useFetch';

function AdminUpload() {
  const [message, setMessage] = useState('');
  const fileInputRef = useRef(null);
  const { fetchData, loading, error } = useFetch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      const formData = new FormData();
      const file = fileInputRef.current.files[0];
      formData.append('file', file);

      try {
        const response = await fetchData('/api/admin/upload', {
          method: 'POST',
          body: formData,
        });

        if (response) {
          setMessage(response.message || 'File uploaded successfully.');
          fileInputRef.current.value = ''; // Clear the file input
        }
      } catch (err) {
        // Error handling is now done in useFetch
        setMessage('Error uploading file.');
      }
    } else {
      setMessage('Please select a file to upload.');
    }
  };

  return (
    <div className="container mx-auto p-8 bg-gray-100 shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Upload New Version</h1>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <input
          type="file"
          accept=".exe"
          ref={fileInputRef}
          className="mb-4 p-2 border border-gray-300 rounded-lg w-full max-w-md bg-white"
        />
        <button
          type="submit"
          className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
          disabled={loading}
        >
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
      {message && <p className="mt-4 text-center text-lg text-gray-800">{message}</p>}
      {error && <p className="mt-4 text-center text-lg text-red-600">{error.message}</p>}
    </div>
  );
}

export default AdminUpload;
