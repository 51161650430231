import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import useFetch from '../hooks/useFetch';

function LeaderboardsPage() {
  const { fetchData, loading: seasonsLoading, error: seasonsError } = useFetch();
  const [seasonsData, setSeasonsData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState('');
  const [leaderboardState, setLeaderboardState] = useState({
    data: [],
    loading: true,
    error: null,
    currentPage: 1,
    totalPages: 1,
  });
  const [sortBy, setSortBy] = useState('Total Points');
  const [sortOrder, setSortOrder] = useState('DESC');
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  useEffect(() => {
    document.title = 'MyStats - Leaderboards';
  }, []);

  useEffect(() => {
    const fetchSeasons = async () => {
      const data = await fetchData('/api/leaderboard/seasons');
      if (data) {
        setSeasonsData(data);
      }
    };
    fetchSeasons();
  }, [fetchData]);

  useEffect(() => {
    if (seasonsData.length > 0) {
      const defaultSeason = seasonsData.find((season) => season.selected)
      || seasonsData.reduce((prev, current) => (prev.id > current.id ? prev : current));
      setSelectedSeason(defaultSeason.id);
      setLeaderboardState((prev) => ({ ...prev, currentPage: 1 }));
    }
  }, [seasonsData]);

  useEffect(() => {
    if (selectedSeason) {
      setLeaderboardState((prev) => ({ ...prev, currentPage: 1 }));
    }
  }, [selectedSeason]);

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      setLeaderboardState((prev) => ({ ...prev, loading: true }));
      try {
        const response = await fetchData('/api/leaderboard/', {
          method: 'POST',
          body: {
            channel: debouncedQuery,
            season: selectedSeason,
            page: leaderboardState.currentPage,
            limit: 10,
            sortBy,
            sortOrder,
          },
        });
        if (response) {
          setLeaderboardState({
            data: response.data,
            totalPages: response.totalPages > 0 ? response.totalPages : 1,
            error: null,
            loading: false,
            currentPage: 1,
          });
        }
      } catch (error) {
        setLeaderboardState((prev) => ({ ...prev, error: error.message, loading: false }));
      }
    };

    if (selectedSeason) {
      fetchLeaderboardData();
    }
  }, [selectedSeason, leaderboardState.currentPage, sortBy, sortOrder, debouncedQuery, fetchData]);

  const debouncedUpdateQuery = useCallback(
    (value) => {
      const debouncedFn = debounce((v) => {
        setDebouncedQuery(v);
      }, 1000);
      debouncedFn(value);
    },
    [setDebouncedQuery],
  );

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    debouncedUpdateQuery(e.target.value);
    setLeaderboardState((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handleSort = (column) => {
    const newSortOrder = sortBy === column && sortOrder === 'DESC' ? 'ASC' : 'DESC';
    setSortBy(column);
    setSortOrder(newSortOrder);
    setLeaderboardState((prev) => ({ ...prev, currentPage: 1 }));
  };

  const handlePageChange = (page) => {
    setLeaderboardState((prev) => ({ ...prev, currentPage: page }));
  };

  const renderSortIndicator = (column) => {
    if (sortBy === column) {
      return sortOrder === 'ASC' ? '↑' : '↓';
    }
    return null;
  };

  function renderTableBody() {
    if (leaderboardState.loading) {
      return (
        <tr>
          <td colSpan="7" className="text-center">Loading...</td>
        </tr>
      );
    }

    if (leaderboardState.error) {
      return (
        <tr>
          <td colSpan="7" className="text-center">Error loading data</td>
        </tr>
      );
    }

    if (leaderboardState.data.length === 0) {
      return (
        <tr>
          <td colSpan="7" className="text-center">No data found</td>
        </tr>
      );
    }

    return (
      <>
        {leaderboardState.data.map((item) => (
          <tr key={item.username || item.id}>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{item.rank}</td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">
              <img
                src={item.profileImageUrl || '/assets/images/user_circle.webp'}
                alt="Profile"
                style={{
                  width: '30px', height: '30px', marginRight: '10px', display: 'inline-block', verticalAlign: 'middle',
                }}
              />
              {item.username}
            </td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{Number(item.totalPoints).toLocaleString()}</td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{Number(item.totalRaces).toLocaleString()}</td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{Number(item.pointsPerRace).toFixed(2)}</td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{Number(item.raceHighScore).toLocaleString()}</td>
            <td className="px-6 py-2 whitespace-nowrap text-sm font-medium text-gray-900">{Number(item.battleRoyaleHighScore).toLocaleString()}</td>
          </tr>
        ))}
        {Array.from({ length: 10 - leaderboardState.data.length }).map((_, index) => (
          <tr key={`empty-${leaderboardState.data.length + index}`}>
            <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">&nbsp;</td>
          </tr>
        ))}
      </>
    );
  }

  if (seasonsLoading && leaderboardState.loading) return <div>Loading...</div>;
  if (seasonsError) return <div>Error loading seasons data</div>;
  // eslint-disable-next-line max-len
  if (leaderboardState.error) return <div>Error loading leaderboard data: {leaderboardState.error}</div>;

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Leaderboard</h1>
      <div className="bg-gray-100 p-4 mb-4 rounded-md">
        <div className="flex space-x-4">
          <div className="flex-1">
            <label htmlFor="season" className="block text-sm font-medium text-gray-700">
              Season:
              <select
                id="season"
                value={selectedSeason}
                onChange={(e) => setSelectedSeason(e.target.value)}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="">Select a season</option>
                {seasonsData && seasonsData.length > 0 ? (
                  seasonsData.map((season) => (
                    <option key={season.id} value={season.id}>
                      {season.name}
                    </option>
                  ))
                ) : (
                  <option value="" disabled>
                    No seasons available
                  </option>
                )}
              </select>
            </label>
          </div>
          <div className="flex-1">
            <label htmlFor="queryParam" className="block text-sm font-medium text-gray-700">
              Lookup Channel:
              <input
                id="queryParam"
                type="text"
                placeholder="Search by channel name"
                value={query}
                onChange={handleQueryChange}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              />
            </label>
          </div>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rank</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Channel</th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('Total Points')}>
              Total Points
              {renderSortIndicator('Total Points')}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('Total Races')}>
              Total Races
              {renderSortIndicator('Total Races')}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('PPR')}>
              PPR
              {renderSortIndicator('PPR')}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('Race HS')}>
              Race HS
              {renderSortIndicator('Race HS')}
            </th>
            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('BR HS')}>
              BR HS
              {renderSortIndicator('BR HS')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-gray-200" style={{ height: '530px', overflowY: 'auto' }}>
          {renderTableBody()}
        </tbody>
      </table>
      <div className="flex justify-between mt-4">
        <button
          type="button"
          onClick={() => handlePageChange(leaderboardState.currentPage - 1)}
          disabled={leaderboardState.currentPage === 1}
          className={`px-4 py-2 rounded ${leaderboardState.currentPage === 1 ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
        >
          Previous
        </button>
        <span className="px-4 py-2">
          Page
          {leaderboardState.currentPage}
          {' '}
          of
          {' '}
          {leaderboardState.totalPages}
        </span>
        <button
          type="button"
          onClick={() => handlePageChange(leaderboardState.currentPage + 1)}
          disabled={leaderboardState.currentPage === leaderboardState.totalPages}
          className={`px-4 py-2 rounded ${leaderboardState.currentPage === leaderboardState.totalPages ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default LeaderboardsPage;
